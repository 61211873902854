import React, { useEffect, useState } from 'react';
import Moment from "react-moment";
import PropTypes from 'prop-types';


const ViewTransaction = ({ viewData }) => {
    const [otherFee, setOtherFee] = useState("");
    const [gratiFee, setGratiFee] = useState("");
    const paymentMethodType = (data) => {
        return data
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    const feeCalculation = () => {
        if (viewData?.paymentMethod === "card") {
            setGratiFee("0.1% + $ 0.20");
            setOtherFee("2.9% + $ 0.30");
        }
        else if (viewData?.paymentMethod === "us_bank_account") {
            setGratiFee("0.2%");
            setOtherFee("0.8%");
        }
    }
    useEffect(() => {
        feeCalculation();
    }, [])

    return (
        <div className='h-[456px] w-[566px] flex flex-col gap-6 font-semibold'>
            <div className="flex w-[545px] gap-9 h-12">
                <div className="flex flex-col w-[260.5px] h-12  gap-1">
                    <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Transaction ID</p>
                    <p className="  h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{viewData?.transactionId}</p>
                </div>
                <div className="flex flex-col w-[260.5px] h-12  gap-1">
                    <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Transaction Date</p>
                    <p className=" h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}><Moment format="DD MMM YYYY ,  hh:mm A" >
                        {viewData?.createdAt}
                    </Moment></p>
                </div>
            </div>

            <div className="flex w-[545px] gap-9 h-12">
                <div className="flex flex-col w-[260.5px] h-12  gap-1">
                    <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Mode of Transaction</p>
                    <p className=" w-fit h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{paymentMethodType(viewData?.paymentMethod)}</p>
                </div>
                <div className="flex flex-col w-[260.5px] h-12  gap-1">
                    <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Remittance</p>
                    <p className=" w-fit h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{viewData?.payeeDetails?.userName || "N/A"}</p>
                </div>
            </div>

            <div className="flex w-[545px] gap-9 h-12">

                <div className="flex flex-col w-[260.5px] h-12  gap-1">
                    <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Total tip given</p>
                    <p className="  h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>$ {viewData?.amount}</p>
                </div>
            </div>
            <p className="w-fit h-6 text-[#000000] text-[16px] font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Transaction Details</p>
            <div className="flex h-6 w-[542px]   gap-9 justify-between">
                <p className="w-fit h-6 text-[#888888] text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Other Fees</p>
                <p className=" h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>$ {otherFee}</p>
            </div>

            <div className="flex h-6 w-[542px]   gap-9 justify-between">
                <p className="w-fit h-6 text-[#888888] text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Grati Fees</p>
                <p className=" h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>$ {gratiFee}</p>
            </div>

            <div className="flex h-12 w-[566px] p-3 gap-9 bg-[#EEEEEE]">
                <div className="flex justify-between w-[542px] h-6">
                    <p className="w-fit h-6 text-[#888888] text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Total Amount</p>
                    <p className=" h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>$ {viewData?.amount}</p>
                </div>
            </div>
        </div>
    );
}
ViewTransaction.propTypes = {
    viewData: PropTypes.shape({
        transactionId: PropTypes.string,
        createdAt: PropTypes.string,
        paymentMethod: PropTypes.string,
        payeeDetails: PropTypes.shape({
            userName: PropTypes.string,
        }),
        amount: PropTypes.number,
    }),
};
export default ViewTransaction

