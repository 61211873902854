export const getDateRange = (range) => {
  let endDate = new Date();
  let startDate;
  switch (range) {
    case "lastWeek":
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
      break;

    case "lastMonth":
      startDate = new Date(endDate);
      startDate.setMonth(endDate.getMonth() - 1);
      break;

    case "lastYear":
      startDate = new Date(endDate);
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      startDate = null;
      break;
  }

  return {
    startDate: startDate?.toISOString() || "",
    endDate: endDate?.toISOString(),
  };
};
