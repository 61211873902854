import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../manage_admins/manageEdit.css";
import {
  Autocomplete,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { searchUser } from "../../../../API/userManagementApi";
import {
  createNewPostImage,
  createNewPostVideo,
  editCollabPostImage,
  editCollabPostVideo,
} from "../../../../API/manageCollabApi";
import Joi from "joi";

const NewPost = ({ setIsModalOpen, postData, newPostAdded, setUploading }) => {
  const [collabType, setCollabType] = useState(
    postData ? Object.keys(postData?.collabType)[0] : " "
  );
  const [post, setPost] = useState(
    postData
      ? { url: postData?.media[0]?.imgUrl, type: postData?.media[0]?.mimeType }
      : ""
  );
  const [collabPost, setCollabPost] = useState([]);
  const [errors, setErrors] = useState("");
  const [startDate, setStartDate] = useState(
    postData ? new Date(postData?.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    postData ? new Date(postData?.endDate) : null
  );
  const [imgRatio, setImgRatio] = useState(
    postData ? postData?.media[0]?.imageRatio : 0
  );
  const [userId, setUserId] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popUpValue, setPopUpValue] = useState(
    postData && Object.keys(postData?.collabType)[0] === "sponsorship"
      ? Object.keys(postData?.collabType?.sponsorship)[0]
      : ""
  );
  const [options, setOptions] = useState([]);
  const [saved, setSaved] = useState(false);
  const [description, setDescription] = useState(
    postData ? postData?.sponsoredText : ""
  );
  const [amount, setAmount] = useState(
    postData ? postData?.amountForPost : null
  );
  const userName = {
    userName: postData
      ? postData?.sponsoredUserDetails?.userName || "Deleted User"
      : null,
    _id: postData ? postData?.sponsoredUserDetails?._id : null,
  };
  const validationData = Joi.object({
    post: Joi.object()
      .required()
      .label("Post")
      .error(new Error("Please Select a Post to Upload")),
    userId: Joi.string()
      .required()
      .label("User Name")
      .error(new Error("Please Select a User Name")),
    collabType: Joi.string()
      .required()
      .empty("")
      .trim()
      .label("Type of Collaboration")
      .error(new Error("Please Select Type of Collaboration")),
    popUpValue: Joi.string().when("collabType", {
      is: "sponsorship",
      then: Joi.string()
        .required()
        .label("Visibilty range")
        .error(new Error("Please Select visility range for Sponsorship")),
      otherwise: Joi.optional().allow("").label("Visibilty range"),
    }),
    startDate: Joi.date()
      .required()
      .label("Start Date")
      .error(new Error("Start Date is required")),
    endDate: Joi.date()
      .min(Joi.ref("startDate"))
      .max(endDate)
      .required()
      .label("End Date")
      .error((errors) => {
        const [error] = errors;
        switch (error.code) {
          case "date.min":
            return new Error("End Date should not be before Start Date");
          case "date.max":
            return new Error(
              "End Date should not be after " + new Date(endDate)
            );
          default:
            return new Error("End Date is Required");
        }
      }),
    amount: Joi.number()
      .required()
      .label("Amount")
      .error(new Error("Please enter valid amount")),
  });
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleVideoPostUpload = (data) => {
    createNewPostVideo(data)
      .then((response) => {
        toast.success("Post Added Successfully", {
          autoClose: 5000,
          toastId: "Post added successfully",
        });
        newPostAdded(true);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error adding new post",
        })
      )
      .finally(() => {
        setUploading(false);
        setSaved(false);
      });
    handleCancel();
  };
  const handleImagePostUpload = (data) => {
    createNewPostImage(data)
      .then((response) => {
        toast.success("Post Added Successfully", {
          autoClose: 5000,
          toastId: "Post added successfully",
        });
        newPostAdded(true);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error adding new post",
        })
      )
      .finally(() => {
        setUploading(false);
        setSaved(false);
      });
    handleCancel();
  };
  const handleEditPost = () => {
    const validation = validationData.validate({
      collabType,
      popUpValue,
      post,
      startDate,
      endDate,
      userId: userId || userName?._id,
      amount,
    });

    if (validation.error) {
      toast.error(validation.error.message, {
        autoClose: 5000,
        toastId: "Validation Error",
      });
      return;
    }
    setUploading(true);
    let data = new FormData();
    if (collabPost && collabPost.length !== 0) {
      if (post.type?.startsWith("video")) {
        data.append("video", collabPost);
      } else {
        data.append("media", collabPost);
      }
    } else {
      data.append("media", JSON.stringify(postData?.media));
    }
    userId
      ? data.append("sponsoredFor", userId)
      : data.append("sponsoredFor", userName?._id);
    popUpValue
      ? data.append("collaborationType", popUpValue)
      : data.append("collaborationType", collabType);
    data.append("amountReceived", amount);
    data.append("startDate", startDate.toISOString());
    data.append("endDate", endDate.toISOString());
    data.append("postId", postData?._id);
    data.append("imageRatio", imgRatio);
    description &&
      data.append("sponsoredText", description.replace(/^\n+|\n+$/g, ""));
    setSaved(true);
    if (post.type?.startsWith("image/")) {
      editCollabPostImage(data)
        .then((response) => {
          toast.success("Post Edited Successfully", {
            autoClose: 5000,
            toastId: "Post Edited Successfully",
          });
          newPostAdded(true);
        })
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "Error adding new post",
          })
        )
        .finally(() => {
          setUploading(false);
          setSaved(false);
        });
      handleCancel();
    } else {
      editCollabPostVideo(data)
        .then((response) => {
          toast.success("Post Edited Successfully", {
            autoClose: 5000,
            toastId: "Post Edited successfully",
          });
          newPostAdded(true);
        })
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "Error adding new post",
          })
        )
        .finally(() => {
          setUploading(false);
          setSaved(false);
        });
      handleCancel();
    }
  };

  const handleSavePost = () => {
    const validation = validationData.validate({
      collabType,
      popUpValue,
      post,
      startDate,
      endDate,
      userId,
      amount,
    });

    if (validation.error) {
      toast.error(validation.error.message, {
        autoClose: 5000,
        toastId: "Validation Error",
      });
      return;
    }
    setUploading(true);
    let data = new FormData();
    popUpValue
      ? data.append("collaborationType", popUpValue)
      : data.append("collaborationType", collabType);
    if (collabPost) {
      if (post.type?.startsWith("video")) data.append("video", collabPost);
      else data.append("media", collabPost);
    }
    data.append("amountReceived", amount);
    data.append("startDate", startDate.toISOString());
    data.append("endDate", endDate.toISOString());
    data.append("sponsoredFor", userId);
    description &&
      data.append("sponsoredText", description.replace(/^\n+|\n+$/g, ""));
    data.append("imageRatio", imgRatio);
    setSaved(true);
    if (post.type?.startsWith("video")) {
      handleVideoPostUpload(data);
    } else {
      handleImagePostUpload(data);
    }
  };

  const handleSubmit = () => {
    if (postData) {
      handleEditPost();
    } else {
      handleSavePost();
    }
  };
  const handleCollabChange = (event) => {
    setCollabType(event?.target?.value);
    if (event?.target?.value === "sponsorship") {
      setOpenSelect(true);
      return;
    }
    setOpenSelect(false);
  };
  const handleOpenSelect = () => {
    setCollabType("");
    setPopUpValue("");
    setOpenSelect(true);
  };
  const handleSponsorClick = (event) => {
    setOpenSelect(true);

    setAnchorEl(event.currentTarget);
    setPopUpOpen(true);
  };

  const handleSponsorship = (value) => {
    setPopUpValue(value);
    setPopUpOpen(false);
    setOpenSelect(false);
    setCollabType("sponsorship");
  };
  const handleSelectClose = (event) => {
    if (
      event?.target?.textContent === "Advertisement" ||
      event?.target?.textContent === "Promotion" ||
      popUpValue
    ) {
      setOpenSelect(false);
    } else if (
      (event?.target?.textContent === "Sponsorship" && !popUpValue) ||
      event?.target?.closest("svg") ||
      event?.target?.closest("path")
    ) {
      toast.warning("Please select visibility range for Sponsorship", {
        autoClose: 5000,
        toastId: "sponsorship visibility range",
      });
      setOpenSelect(true);
    } else {
      setOpenSelect(false);
    }
  };

  const handleFileSelect = (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setErrors("");

    if (
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png"
    ) {
      handleImageFile(file);
    } else if (file.type === "video/mp4") {
      handleVideoFile(file);
    } else {
      setPost("");
      setErrors(
        "Unsupported file format. Only JPEG, JPG, PNG images and MP4 videos are allowed."
      );
    }
  };
  const handleImageFile = (file) => {
    const minSizePhoto = 25 * 1024;
    const maxSizePhoto = 5 * 1024 * 1024;
    if (file.size >= minSizePhoto && file.size <= maxSizePhoto) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          const ratio = img.naturalHeight / img.naturalWidth;
          setImgRatio(ratio);
          setPost({
            url: URL.createObjectURL(file),
            type: file.type,
          });
          setCollabPost(file);
        };
      };
      reader.readAsDataURL(file);
    } else {
      setImgRatio(0);
      setPost(null);
      setCollabPost([]);
      setErrors("Photo size must be between 25 Kilobytes to 5 Mb.");
    }
  };

  const handleVideoFile = (file) => {
    const maxSizeVideo = 50 * 1024 * 1024;
    const maxVideoDuration = 30;
    if (file.size <= maxSizeVideo) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        if (video.duration <= maxVideoDuration) {
          const ratio = video.videoHeight / video.videoWidth;
          setImgRatio(ratio);
          setPost({ url: URL.createObjectURL(file), type: "video" });
          setCollabPost(file);
        } else {
          setImgRatio(0);
          setPost(null);
          setCollabPost([]);
          setErrors("Maximum video duration allowed is 30 seconds.");
        }
      };
      video.src = URL.createObjectURL(file);
    } else {
      setPost(null);
      setErrors("Video file size must be less than 50 Mb.");
    }
  };

  const fetchOptions = (inputValue) => {
    if (inputValue !== "") {
      searchUser(inputValue)
        .then((response) => {
          setOptions(response?.data?.data);
        })
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "error fetching users",
          })
        );
    }
  };
  const handleOptionChange = (event, value) => {
    setUserId(value?._id);
  };
  const handleInputChange = (e) => {
    setDescription(e?.target?.value);
  };
  const handleStartDate = (e) => {
    const selectedDate = new Date(e);
    selectedDate.setHours(0, 0, 0, 0);
    setStartDate(selectedDate);
  };
  const handleEndDate = (e) => {
    const selectedDate = new Date(e);
    selectedDate.setHours(23, 59, 59, 999);
    setEndDate(selectedDate);
  };

  const handleAmountChange = (e) => {
    const inputValue = e?.target?.value;
    const changedValue = inputValue.replace(/^\+/, "");
    const intValue = parseInt(changedValue);
    if (inputValue === "" || (!isNaN(intValue) && intValue > "0")) {
      setAmount(intValue);
    } else {
      setAmount("");
    }
  };
  const renderMedia = () => {
    if (!post) {
      return (
        <div>
          <AddLinkOutlinedIcon className="!text-3x text-[#524CBB]" />
          <p className="text-[#524CBB]">Upload Photo/Video</p>
        </div>
      );
    }

    if (post.type?.startsWith("image")) {
      return (
        <div
          className="relative flex flex-row justify-center items-center"
          style={{
            width: imgRatio >= 0.55 ? "auto" : "100%",
            height: imgRatio >= 0.55 ? "100%" : "auto",
          }}>
          <img
            key={post.url}
            src={post.url}
            alt=""
            className="w-full h-full object-contain"
          />
          {renderCollabType()}
        </div>
      );
    }

    return (
      <div
        className="relative flex flex-row justify-center items-center"
        style={{
          width: imgRatio >= 0.55 ? "auto" : "100%",
          height: imgRatio >= 0.55 ? "100%" : "auto",
        }}>
        <video key={post.url} controls className="h-full w-full">
          <source src={post.url} type="video/mp4" />
          <track kind="captions" src="#" label="English" /> Your browser does
          not support the video tag.
        </video>
        {renderCollabType()}
      </div>
    );
  };

  const renderCollabType = () => {
    return (
      collabType?.length > 1 && (
        <div className="w-fit h-fit px-[5px] py-[2px] text-[#FFFFFF] bg-[#FF6F29] bg-opacity-50 absolute top-[2%] left-[2%] rounded-sm">
          <p
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            className=" text-[10px] font-[100] ">
            {collabType === "sponsorship"
              ? "SPONSORED"
              : collabType.charAt(0).toUpperCase() + collabType.slice(1)}
          </p>
        </div>
      )
    );
  };
  let arrowIcon = null;
  if (!popUpValue && popUpOpen) {
    arrowIcon = <ArrowLeftIcon sx={{ opacity: 0.54 }} />;
  } else if (!popUpValue && !popUpOpen) {
    arrowIcon = <ArrowRightIcon sx={{ opacity: 0.54 }} />;
  }
  let sponsorshipText = "Sponsorship";

  if (popUpValue) {
    if (popUpValue === "inOrganization") {
      sponsorshipText = "Sponsorship > In Organization";
    } else if (popUpValue === "community") {
      sponsorshipText = "Sponsorship > Community";
    } else {
      sponsorshipText = `Sponsorship > ${popUpValue}`;
    }
  }

  return (
    <div className="card h-[500px] flex flex-col gap-5 font-semibold justify-between">
      <div className="w-full flex flex-col gap-2">
        <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
          Select Post To Upload *
        </p>
        <button
          className="w-full bg-indigo-500 bg-opacity-20 rounded-xl border-2 border-[#524CBB] flex flex-row justify-center items-center relative overflow-hidden"
          style={post ? { height: "300px" } : { height: "150px" }}
          type="button"
          onClick={() => {
            document.getElementById("add-post").click();
          }}>
          {renderMedia()}

          <input
            type="file"
            accept="image/png,  image/jpeg, image/jpg, video/mp4 "
            id="add-post"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
        </button>
        {!post && !errors && (
          <p
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            className="text-[#524CBB] text-[16px] font-[500] ">
            Large file might take some time to upload
          </p>
        )}
        {errors && (
          <p
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            className="text-[red] text-[14px] font-[500] ">
            {errors}
          </p>
        )}
      </div>
      <div className="h-fit flex flex-row justify-between gap-8">
        <div className="w-1/2 flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            Enter User Name *
          </p>

          <Autocomplete
            id="userName"
            name="userName"
            sx={{
              ".MuiOutlinedInput-root": {
                height: "50px",
                borderRadius: "6px",
              },
              ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                paddingTop: "4px",
                fontFamily: "Plus Jakarta Sans !important",
                fontWeight: "semi-bold",
              },
              ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "Plus Jakarta Sans !important",
                fontWeight: "semi-bold",
              },
            }}
            defaultValue={postData && userName}
            options={options}
            getOptionLabel={(option) => option?.userName}
            onChange={handleOptionChange}
            onInputChange={(event, newInputValue) =>
              fetchOptions(newInputValue)
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div className="w-1/2 flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            Type of Collaboration *
          </p>
          <Select
            labelId="collaboration-select-label"
            id="collaboration-select"
            open={openSelect}
            onOpen={handleOpenSelect}
            onClose={handleSelectClose}
            value={collabType}
            onChange={handleCollabChange}
            style={{
              height: "50px",
              fontFamily: "Plus Jakarta Sans",
              padding: "0px",
              borderRadius: "6px",
            }}>
            <MenuItem key={1} disabled value=" ">
              Select Collaboration
            </MenuItem>
            <MenuItem
              key="sponsorship"
              value="sponsorship"
              onClick={handleSponsorClick}
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
                display: "flex",
                justifyContent: "space-between",
              }}>
              {sponsorshipText}
              {arrowIcon}
            </MenuItem>
            <MenuItem
              key="advertisement"
              value="advertisement"
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}>
              Advertisement
            </MenuItem>
            <MenuItem
              key="promotion"
              value="promotion"
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}>
              Promotion
            </MenuItem>
          </Select>
          <Popover
            id="nested-menu"
            open={popUpOpen}
            anchorEl={anchorEl}
            onClose={() => setPopUpOpen(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}>
            <MenuItem
              value="In Organization"
              onClick={() => handleSponsorship("inOrganization")}>
              In Organization
            </MenuItem>
            <MenuItem
              value="Community"
              onClick={() => handleSponsorship("community")}>
              Community
            </MenuItem>
          </Popover>
        </div>
      </div>
      <div className="h-fit flex flex-row justify-between gap-8">
        <div className="w-1/2 flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            Start Date *
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                ".MuiOutlinedInput-input": {
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                },
                ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "50px",
                  borderRadius: "6px",
                },
              }}
              disablePast
              onChange={handleStartDate}
              format="DD/MM/YYYY"
              value={startDate ? dayjs(startDate) : null}
            />
          </LocalizationProvider>
        </div>

        <div className="w-1/2 flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            End Date *
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                ".MuiOutlinedInput-input": {
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                },
                ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "50px",
                  borderRadius: "6px",
                },
              }}
              disablePast
              onChange={handleEndDate}
              format="DD/MM/YYYY"
              value={endDate ? dayjs(endDate) : null}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="h-fit flex flex-row justify-between gap-8">
        <div className="w-[47%] flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            Enter Amount *
          </p>
          <input
            type={"number"}
            id="amount"
            name="amount"
            min={0}
            step={1}
            value={amount}
            onChange={(e) => handleAmountChange(e)}
            className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            placeholder="Enter Amount in $"
          />
        </div>
      </div>
      <div className="h-fit flex flex-row justify-between gap-8">
        <div className="w-full flex flex-col gap-2">
          <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
            Add Description
          </p>
          <textarea
            type={"text"}
            id="description"
            name="description"
            value={description}
            onChange={(e) => handleInputChange(e)}
            maxLength={200}
            className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            placeholder="Add Description"
          />
        </div>
      </div>

      <div className="h-fit flex flex-row justify-center gap-4 mb-[10px]">
        <button
          className="text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px] px-[16px] py-[10px]"
          type="button"
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="bg-[#524CBB] text-[white] text-[14px] font-medium  px-[16px] py-[10px] rounded-[6px]"
          type="submit"
          disabled={saved}
          style={saved ? { color: "#888888", background: "#EEEEEE" } : {}}
          onClick={handleSubmit}>
          {postData?.sponsoredUserDetails?.userName ? "Edit Post" : "Save Post"}
        </button>
      </div>
    </div>
  );
};
NewPost.propTypes = {
  setIsModalOpen: PropTypes.func,
  postData: PropTypes.object,
  newPostAdded: PropTypes.func,
  setUploading: PropTypes.func,
};
export default NewPost;
