import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import "../../dashboard/dashboard.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Moment from "react-moment";
import GenericModal from "../../../../GenericModal";
import NewPost from "./new_post_modal";
import ViewPost from "./view_post_modal";
import { collabPosts } from "../../../../API/manageCollabApi";
import { toast } from "react-toastify";
import Video from "../../../../../Assets/images/icons/video_circle.png";
import DeletePost from "./delete_post";

const tableHeading = [
  "S. No.",
  "Name",
  "Type of Collaboration",
  "Amount Received",
  "Date and Time",
  "Attachment ",
];

export default function AddPost() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [newPostModal, setNewPostModal] = useState(false);
  const [viewPostModal, setViewPostModal] = useState(false);
  const [postData, setPostData] = useState({});
  const [action, setAction] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleNewpost = () => {
    setAction(false);
    setNewPostModal(!newPostModal);
  };
  const handleViewPost = (row) => {
    setAction(false);
    setPostData(row);
    setViewPostModal(!viewPostModal);
  };
  const handleDeleteView = (data) => {
    setViewPostModal(false);
    setIsDeleteModalOpen(data);
  };
  const handleEditView = (data) => {
    setViewPostModal(false);
    setIsEditModalOpen(data);
  };
  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );
  const renderActionButtons = (row) => {
    return row?.media?.[0] ? (
      <button
        type="button"
        className="w-[46px] h-[46px] rounded-md bg-gray-300 relative"
        onClick={() => handleViewPost(row)}>
        <img
          src={
            row?.media[0]?.mimeType?.startsWith("image/")
              ? row?.media[0]?.imgUrl
              : row?.media[0]?.thumbNail
          }
          alt=""
          className="object-fill w-[inherit] h-[inherit] m-auto rounded-md"
        />
        {row?.media[0]?.thumbNail && (
          <img
            src={Video}
            alt=""
            className="w-[28px] h-[28px] absolute top-[9px] left-[9px] bg-[#EEEEEE] rounded-full"
          />
        )}
      </button>
    ) : (
      <p
        className="text-[14px] font-[600]"
        style={{
          color: "rgba(136, 136, 136, 0.5)",
          fontFamily: "Plus Jakarta Sans",
        }}>
        N/A
      </p>
    );
  };

  const handleAddPostData = () => {
    setLoading(true);
    if (viewAll) {
      collabPosts(page, limit)
        .then((response) => {
          setTableData(response?.data?.data?.postsList);
          setTotalCount(response?.data?.data?.totalCount);
        })
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "error fetching collab post data",
          })
        )
        .finally(() => setLoading(false));
    } else {
      collabPosts(1, 5)
        .then((response) => setTableData(response?.data?.data?.postsList))
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "error fetching collab post data",
          })
        )
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (action) {
      handleAddPostData();
    }
  }, [action]);

  useEffect(() => {
    handleAddPostData();
  }, [viewAll, page, limit]);

  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const limitOptions = [10, 25, 50, 75, 100];
  return (
    <Paper
      className="randomClass1 flex-grow flex flex-col justify-between gap-6 p-6 mb-6"
      style={{ borderRadius: "12px", justifyContent: "flex-start" }}
      component={Paper}>
      <div className="flex justify-between" style={{ alignItems: "baseline" }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Add Posts
        </p>
        <div className="flex gap-3 ">
          {loadingPost && (
            <div className="flex flex-row bg-indigo-500 bg-opacity-20 w-[180px] justify-between rounded-[6px] py-[10px] px-4 border-2 border-[#524CBB]">
              <CircularProgress size={18} style={{ color: "#524CBB" }} />
              <p
                className="font-bold text-[#524CBB]"
                style={{
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                  lineHeight: "24px",
                  fontWeight: "500",
                  textSize: "16px",
                  padding: "0",
                }}>
                Uploading Post
              </p>
            </div>
          )}
          <button
            type="button"
            className="flex flex-row justify-between items-center w-[174px] gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
            onClick={handleNewpost}>
            <p
              className="font-bold text-white"
              style={{
                fontFamily: "Plus Jakarta Sans,sans-serif",
                lineHeight: "24px",
                fontWeight: "500",
                textSize: "16px",
                padding: "0",
              }}>
              Create a New Post
            </p>
          </button>
        </div>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (
        <TableContainer className="card w-auto overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading?.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          key={heading}
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[194px] h-13">
                      {renderTableCell(<p>{index + 1}</p>)}
                      {renderTableCell(
                        row?.sponsoredUserDetails?.userName ||
                          renderTextContent("Deleted User", {
                            fontStyle: "italic",
                          })
                      )}
                      {renderTableCell(
                        Object.keys(row?.collabType)[0]
                          ?.charAt(0)
                          .toUpperCase() +
                          Object.keys(row?.collabType)[0]?.slice(1) ||
                          renderTextContent("N/A")
                      )}
                      {renderTableCell(
                        row?.amountForPost || renderTextContent("N/A")
                      )}
                      {renderTableCell(
                        <Moment format="DD MMM YYYY ,  hh:mm A">
                          {row?.createdAt}
                        </Moment>
                      )}

                      {renderTableCell(renderActionButtons(row))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}
      {newPostModal && (
        <GenericModal
          open={newPostModal}
          handleClose={() => setNewPostModal(false)}
          title="Add New Post"
          content={
            <NewPost
              setIsModalOpen={setNewPostModal}
              newPostAdded={(e) => {
                setAction(e);
              }}
              setUploading={(e) => {
                setLoadingPost(e);
              }}
            />
          }
          isCross={true}
          width="600px"
          height="max-content"
        />
      )}
      {isDeleteModalOpen && (
        <GenericModal
          open={isDeleteModalOpen}
          handleClose={() => setIsDeleteModalOpen(false)}
          content={
            <DeletePost
              setIsModalOpen={setIsDeleteModalOpen}
              postId={postData?._id}
              isDeleted={(e) => setAction(e)}
            />
          }
          isCross={true}
          width="391px"
          height="343px"
        />
      )}
      {isEditModalOpen && (
        <GenericModal
          open={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          title="Edit Post"
          content={
            <NewPost
              setIsModalOpen={setIsEditModalOpen}
              postData={postData}
              newPostAdded={(e) => setAction(e)}
              setUploading={(e) => setLoadingPost(e)}
            />
          }
          isCross={true}
          width="600px"
          height="max-content"
        />
      )}
      {viewPostModal && (
        <GenericModal
          open={viewPostModal}
          handleClose={() => setViewPostModal(false)}
          title="View Post"
          content={
            <ViewPost
              postData={postData}
              handleDelete={handleDeleteView}
              handleEdit={handleEditView}
            />
          }
          isCross={true}
          width="500px"
          height="max-content"
        />
      )}
      {viewAll ? (
        <div className="flex justify-between mt-auto">
          <Select
            labelId="pagination-limit-select-label"
            value={limit}
            onChange={handlePaginationLimitChange}
            className="w-[66px] h-11 bg-[#EEEEEE] "
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "500",
              width: "fit-content",
            }}>
            {limitOptions.map((options) => (
              <MenuItem
                key={options}
                style={{
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                }}
                value={options}>
                {options}
              </MenuItem>
            ))}
          </Select>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(totalCount / limit)}
              defaultPage={1}
              siblingCount={3}
              shape="rounded"
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      ) : (
        <div className="mt-auto">
          <button
            className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]"
            type="button"
            onClick={handleViewAllClick}>
            View All
          </button>
        </div>
      )}
    </Paper>
  );
}
