import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination, MenuItem, Select, Stack } from '@mui/material';
import Eye from "../../../../Assets/images/icons/eye.png";
import User from "../../../../Assets/images/icons/user.png";
import GenericModal from "../../../GenericModal";
import ViewReview from "./viewReview";
import { listRevData } from "../../../API/reviewManagementApi";
import Video from "../../../../Assets/images/icons/video_circle.png";
import Moment from "react-moment";
import '../manage_admins/manageEdit.css'
import { useSelector } from "react-redux";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import { getDateRange } from "../../CustomDatePicker/DateRangeFunction";
import { toast } from "react-toastify";
import Attachments from "./attachments";
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from "framer-motion"
import { useLocation } from "react-router-dom";


const DateRangeFilter = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "lastYear",
    label: "Last Year",
  },
  {
    value: "lastMonth",
    label: "Last Month",
  },
  {
    value: "lastWeek",
    label: "Last Week",
  },
  {
    value: "customDateRange",
    label: "Custom Date Range",
  },
]
export default function ReviewManagement() {
  const { state } = useLocation();
  const [selectedButton, setSelectedButton] = useState(state ? state?.type : 1);
  const [tableData, setTableData] = useState([]);
  const permissions = useSelector((state) => state?.user?.permissions[0]);
  const role = useSelector((state) => state.user.role);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [viewData, setViewData] = useState();
  const [action, setAction] = useState(true);
  const [reviewFilter, setReviewFilter] = useState("");
  const [customTimeFilter, setCustomTimeFilter] = useState("all");
  const [customRange, setCustomRange] = useState(false);
  const [customTimeRange, setCustomTimeRange] = useState({
    startDate: "",
    endDate: ""
  })
  const [isView, setIsView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState([]);
  const [loading, setLoading] = useState(true);
  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const fetchUserData = async () => {
    setLoading(true);
    listRevData(limit, reviewFilter, (page), customTimeRange?.startDate, customTimeRange?.endDate)
      .then((response) => {
        setTableData(response?.data?.data?.reviewManagementData);
        setTotalCount(response?.data?.data?.reviewManagementDataCount);
      })
      .catch((error) => toast.error(error?.message, {
        autoClose: 5000,
        toastId: "sfg"
      }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUserData();
  }, [limit, page, reviewFilter, customTimeRange]);
  useEffect(() => {
    if (!action) { fetchUserData(); }
    setAction(true);
  }, [action]);



  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);

    switch (buttonIndex) {
      case 1:
        setReviewFilter("");
        setPage(1);
        break;
      case 2:
        setReviewFilter("isDeleted");
        setPage(1);
        break;
      case 3:
        setReviewFilter("isReported");
        setPage(1);
        break;
      default:
        setPage(1);
        break;

    }
  };


  const handleMediaClick = (attachmentData) => {
    setSelectedAttachment(attachmentData);
    handleOpenAttachmentModal();
  };
  const handleOpenAttachmentModal = () => {
    setIsAttachmentModalOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setIsAttachmentModalOpen(false);
  };


  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsView(!isView);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsView(!isView);
  };

  const handleClick = (data) => {
    setViewData(data);
    handleOpenModal();

    setIsView(!isView);
  };
  const handleTimeChange = (e) => {
    setCustomTimeFilter(e?.target?.value);
    if (e?.target?.value === "customDateRange") {
      setCustomRange(true);
    } else {
      const dateRange = getDateRange(e?.target?.value);
      setCustomRange(false);
      setCustomTimeRange(dateRange);
    }
    setPage(1);
  };
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };


  const handleCloseDatePicker = () => {
    setCustomRange(false);
  };
  const handleDateRange = (dateObj) => {
    setCustomTimeRange(dateObj);
  };
  const handleActionChange = (e) => {
    setAction(e);
    setIsModalOpen(e);
  }
  const title = ["Review Details"];
  const limitOptions = [10, 25, 50, 75, 100];

  return (
    <Paper
      className=" h-[100%] max-h-[87vh] p-6 pb-6 flex flex-col justify-between gap-6  "
      style={{ borderRadius: "12px" }}
      component={Paper}
    >
      <div
        className="flex flex-row justify-between"
        style={{ alignItems: "baseline" }}
      >
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}
        >
          Review and Feedback Management
        </p>
        <div
          className="flex gap-3 h-11 font-[600] text-[14px]"
          style={{ fontFamily: "Plus Jakarta Sans" }}
        >
          <Select
            labelId="pagination-limit-select-label"
            id="pagination-limit-select"
            value={customTimeFilter || "all"}
            displayEmpty
            onChange={
              (e) => {
                handleTimeChange(e)
              }}
            className="flex justify-between items-center  rounded-[6px] min-w-[110px] gap-1 py-2 px-[14px] bg-[#EEEEEE]"
            style={{
              fontFamily: "Inter",
              lineHeight: "20px",
              fontWeight: "500",
              textSize: "14px",
              padding: "0",
              color: "#888888",
            }}
          >
            {DateRangeFilter.map((item) =>
              <MenuItem
                key={item}
                style={{
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                }}
                value={item?.value}
                onClick={
                  (e) => {
                    if (e?.target?.outerText === "Custom Date Range") {
                      setCustomRange(true)
                    }
                  }}
              >
                {item?.label}
              </MenuItem>
            )}

          </Select>
          {customRange && <CustomDatePicker setDateRange={customTimeRange} getDateRange={(e) => handleDateRange(e)} onClose={handleCloseDatePicker} right={"68px"} top={"120px"} />}
        </div>
      </div>
      <div className="switcher flex justify-evenly bg-[#D9D9D9] rounded-[6px] w-fit h-11">
        <button
          type="button"
          className={`bg-red-500 rounded-l-[6px] ${selectedButton === 1 ? "bg-pink-200" : ""
            }  w-[163.5px] h-11 py-[10px] px-4`}
          style={{
            backgroundColor: selectedButton === 1 ? "#524CBB" : "#F8F8F8",
            color: selectedButton === 1 ? "white" : "black",
          }}
          onClick={() => handleButtonClick(1)}
        >
          Active Reviews
        </button>
        <button
          type="button"
          style={{
            backgroundColor: selectedButton === 2 ? "#524CBB" : "#F8F8F8",
            color: selectedButton === 2 ? "white" : "black",
          }}
          onClick={() => handleButtonClick(2)}
          className=" w-[163.5px] h-11 border-l-[1px]  px-4"
        >
          Deleted Reviews
        </button>

        <button
          type="button"
          style={{
            backgroundColor: selectedButton === 3 ? "#524CBB" : "#F8F8F8",
            color: selectedButton === 3 ? "white" : "black",
          }}
          onClick={() => handleButtonClick(3)}
          className="rounded-r-[6px] w-[163.5px] h-11 border-l-[1px]  px-4"
        >
          Reported Reviews
        </button>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>

      )}
      {!loading && <TableContainer className="card w-auto overflow-auto">
        {tableData?.length !== 0 ? <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.8 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="w-[233px] h-11 p-3">
                <TableCell className="w-[233px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    S. No.
                  </p>
                </TableCell>
                <TableCell className="w-[233px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Date and time
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[213.5px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Name of Give Thanks
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[213.5px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Name of Get Thanks
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[213.5px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Emoticons
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[26px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Comments
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[213.5px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Attachments
                  </p>
                </TableCell>
                <TableCell align="left"
                  className="w-[179px] h-11 p-3">
                  <p
                    className="text-[14px] font-[600] text-[#888888]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                  >
                    Action
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow
                  key={row?._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="w-[179px] h-13"
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#888888",
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <p>{index + 1}</p>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Moment format="DD MMM YYYY hh:mm A" >
                      {row?.createdAt}
                    </Moment>
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row?.giveThanksDetails?.userName ? <p >{row?.giveThanksDetails?.userName}</p> : <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans", fontStyle: "italic" }}>Deleted User</p>}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row?.getThanksDetails?.userName ? <p >{row?.getThanksDetails?.userName}</p> : <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans", fontStyle: "italic" }}>Deleted User</p>}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <p>{row?.icon === 'RECOGNITION' && '🤝 Recognition'}
                      {row?.icon === 'CELEBRATION' && '🙌 Celebration'}
                      {row?.icon === 'GRATITUDE' && '🙏 Gratitude'}
                      {row?.icon === 'APPRECIATION' && '👍 Appreciation'}</p>
                    {row?.icon === undefined && <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      textAlign: "justify",
                      fontFamily: "Plus Jakarta Sans",
                    }}
                  >

                    {row?.sayThanks ? <p

                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "194px",
                        textOverflow: "ellipsis",
                        width: "92%",
                      }}
                    >{row?.sayThanks}</p> : <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}

                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {row?.media[0] ? (<button type="button" className="w-[46px] h-[46px] rounded-md bg-gray-300 relative" onClick={(e) => handleMediaClick(row?.media)}>
                      <img

                        src={(row?.media[0]?.mimeType?.startsWith("image/") ? row?.media[0]?.imgUrl : row?.media[0]?.thumbNail) || User}
                        alt="media"
                        className="object-fill w-[inherit] h-[inherit] m-auto rounded-md"
                      />
                      {row?.media[0]?.thumbNail && <img src={Video} alt="" className="w-[28px] h-[28px] absolute top-[9px] left-[9px] bg-[#EEEEEE] rounded-full" />}
                    </button>
                    ) : <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "194px" }}>
                    <div className="flex justify-left gap-[10px]">
                      <button type="button"
                        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
                        onClick={() => { handleClick(row) }}
                      >
                        <img src={Eye} alt="" className="w-5 h-5" />
                      </button>

                    </div>
                    {row?.action}
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </motion.div> : <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">No data found</p>}
      </TableContainer>}

      {
        isView && (
          <GenericModal
            open={isModalOpen}
            handleClose={handleCloseModal}
            content={
              <ViewReview
                data={viewData}
                setIsModal={
                  (e) => handleActionChange(e)
                }
                setIsOpenModal={setIsModalOpen}
                isView={isView}
                setIsView={setIsView}
                permission={role === 0 || permissions?.REVIEW_AND_FEEDBACK?.fullAccess}
                selectedButton={selectedButton}
              />
            }
            isCross={true}
            title={title[0]}
            width="631px"
            height="fit-content"
          />

        )
      }
      {
        selectedAttachment && (
          <GenericModal
            open={isAttachmentModalOpen}
            handleClose={handleCloseAttachmentModal}
            content={
              <Attachments
                attachmentData={selectedAttachment}
              />
            }
            isCross={true}
            title={"Attachments"}
            width="631px"
            height="690px"
          />
        )
      }

      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content"
          }}
        >
          {limitOptions.map((options) => (<MenuItem
            style={{
              "&:hover": {
                backgroundColor: "#EEEEEE",
              },
            }}
            value={options}
            key={options}
          >
            {options}
          </MenuItem>
          ))}
        </Select>
        <Stack spacing={2} >
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper >
  );
}
