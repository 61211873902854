import React from 'react';
import Trash from "../../../../Assets/images/icons/trash_white.png";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { reviewActions } from '../../../API/reviewManagementApi';

const DeleteReview = ({ setIsOpenModal, setIsModal, setIsDelete, data }) => {
    const handleClose = () => {
        setIsOpenModal(false);
    };

    const handleDelete = () => {
        const postForm = {
            _id: data?._id,
            sayThanks: data?.sayThanks,
            actionType: "delete"
        }
        reviewActions(postForm)
            .then((res) => {
                setIsDelete(true);
                toast.error("Review Deleted ", {
                    autoClose: 5000,
                    toastId: "rgevx"
                });
                handleClose();
                setIsModal(false);
            })
            .catch((error) => toast.error(error?.message, {
                autoClose: 5000, toastId: "ef"
            }));
    }

    return (
        <div className='h-auto flex flex-col gap-5 font-semibold'>
            <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">
                <div className=" gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#D33939]">
                    <img src={Trash} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" />
                </div>
                <p className='font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] h-[30px]'>Are you sure you want to delete this review</p>
                <p className='font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]'>Are your sure you want to delete this review from the system?</p>
            </div>
            <div className="flex gap-4 w-[162px] h-11  m-auto mt-[20px]">
                <button className='w-[87px] bg-[#EEEEEE] rounded-[6px] ' onClick={handleClose}> Cancel</button>
                <button className='w-[59px] bg-[#524CBB] rounded-[6px] text-white' onClick={handleDelete}> Yes</button>
            </div>
        </div>
    );
}

DeleteReview.propTypes = {
    setIsOpenModal: PropTypes.func,
    setIsModal: PropTypes.func,
    setIsDelete: PropTypes.func,
    data: PropTypes.object
};

export default DeleteReview;
