import React from "react";
import user from '../../../../Assets/images/icons/user.png'
import heart from '../../../../Assets/images/icons/heart.png'
import money_send from '../../../../Assets/images/icons/money_send.png'
import money_recieve from '../../../../Assets/images/icons/money_recieve.png'
import wallet from '../../../../Assets/images/icons/wallet.png'
import dollar_circle from '../../../../Assets/images/icons/dollar_circle.png'
import login from '../../../../Assets/images/icons/login.png'
import topology1 from '../../../../Assets/images/icons/topo1.png'
import ArrowRight from '../../../../Assets/images/icons/arrowRight.png'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Cards = (color) => {
    const { backgroundColor, darkColor, imgIndex, cardData } = color;
    const cardName = ["Total Registered users", "Total number of reviews / feedback", "Total number of Give Thanks Profiles", "Total Number of Get Thanks Profiles", "Total transactions made", 'Total Transaction Amount', "Total number of daily sign up"]
    const cardDets = [cardData]
    const iconArr = [user, heart, money_send, money_recieve, wallet, dollar_circle, login, dollar_circle];
    const divStyle = {
        backgroundColor: backgroundColor,
    }
    const divStyleDark = {
        backgroundColor: darkColor
    }
    const navigate = useNavigate();
    const handleNavigation = (i) => {
        switch (i) {
            case 0: case 2: case 3: case 6: navigate("/user_management", {
                state: {
                    type: i
                },
            }); break;
            case 1: navigate("/review_management"); break;
            case 4: case 5: navigate("/finance_management"); break;
            default: toast.error("Error navigating to respective page", {
                toastId: "navigation error", autoClose: 5000
            })
        }
    }

    return (
        <div className="w-[367px] h-[220px] rounded-r-xl rounded-t-xl rounded-l-xl flex-col justify-start items-start inline-flex bg-cover bg-center" style={divStyle}>
            <img src={topology1} alt="" className="relative" />
            <div className="relative -top-[215px] flex-col justify-start items-start gap-4 flex z-0 p-4">
                <div className="justify-start items-start inline-flex">
                    <div className="w-[52px] h-[52px] flex justify-center items-center rounded-full border border-white">
                        <img src={iconArr[imgIndex]} width={"28px"} height={"28px"} alt="" />
                    </div>
                    <div className="w-7 h-7 justify-center items-center flex">
                    </div>
                </div>

                <div className="relative flex flex-col">
                    <p className="text-white text-sm font-medium font-['Plus Jakarta Sans'] ">
                        {cardName[imgIndex]}
                    </p>
                    <p className="-mt-4 text-white text-[54px] font-extrabold font-['Plus Jakarta Sans']">
                        {cardDets}
                    </p>
                </div>

            </div>

            <button type="button" className="w-[367px] relative -top-[211px] h-11 -mt-4 rounded-bl-xl rounded-br-xl" style={divStyleDark} onClick={() => handleNavigation(imgIndex)}>
                <div className="flex flex-row p-3 items-center justify-between cursor-pointer">
                    <div className="text-white text-sm font-medium font-['Plus Jakarta Sans'] " >
                        View All
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex">
                        <div className="w-6 h-6 relative">
                            <img src={ArrowRight} width={"24px"} height={"24px"} alt="" />
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default Cards;


