import React, { useEffect, useState } from "react";
import { downloadUserQR, fetchOrderDetails } from "../../../API/orderApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Moment from "react-moment";
import CircularProgress from "@mui/material/CircularProgress";
import WestIcon from "@mui/icons-material/West";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const ViewOrder = ({ orderId, setIsViewOpen }) => {
  const [orderDetails, setOrderDetails] = useState({});
  const [itemDetails, setItemDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setIsViewOpen(false);
  };
  const totalOrderPrice = (orderPrice, taxPrice) => {
    const orderPriceNum = Number(orderPrice);
    const taxPriceNum = Number(taxPrice);
    if (!isNaN(orderPriceNum) && !isNaN(taxPriceNum)) { 
        const price = orderPriceNum + taxPriceNum;
        return `$${price.toFixed(2)}`;
    }
   return `$${orderPriceNum.toFixed(2)}`;
}
  const handleDownloadQRCode = () => {
    downloadUserQR(orderDetails?.userDetails?._id)
      .then((response) => {
        const imageBase64 = response?.data?.data[0]?.base64;
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + imageBase64;
        link.download = `${orderDetails?.userDetails?.userName}_QR_code.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error saving image",
        });

      })
  };
  useEffect(() => {
    setLoading(true);
    fetchOrderDetails(orderId)
      .then((response) => {
        setOrderDetails(response?.data?.data[0]);
        setItemDetails(response?.data?.data?.map((order) => order.items));
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "error fetching order details data",
        })
      )
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      <div className="flex gap-7 flex-col" id={orderId}>
        <div className="h-fit flex flex-row justify-start items-center mb-[10px] gap-4">
          <button type="button" onClick={handleCancel}>
            <WestIcon style={{ color: "#888888", opacity: "0.5" }} />
          </button>
          <p
            className="text-[18px] font-[700] text-black"
            style={{ fontFamily: "Plus Jakarta Sans" }}>
            View Order Details
          </p>
        </div>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (

        <div className="card  flex flex-col gap-5 font-semibold justify-between pb-4">
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                User Name
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.userDetails?.userName}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Email
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.userDetails?.email}
              </p>
            </div>
          </div>
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Address Line 1
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.addressLine1}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Address Line 2
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.addressLine2}
              </p>
            </div>
          </div>

          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                City
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.city}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                State
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.state}
              </p>
            </div>
          </div>
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Country
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.country}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Zip Code
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.addressesDetails?.pincode}
              </p>
            </div>
          </div>
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Ordered On
              </p>
              <Moment
                format="DD MMM YYYY ,  hh:mm A"
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.createdAt }
              </Moment>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Order Price
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {`$${orderDetails?.orderPrice?.toFixed(2)}`}
              </p>
            </div>
          </div>
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Cart Item Price
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {`$${orderDetails?.cartItemPrice?.toFixed(2)}`}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Shipping fee
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {`$${orderDetails?.shippingFee?.toFixed(2)}`}
              </p>
            </div>
            
          </div>
          <div className="h-fit w-full flex flex-row justify-between gap-8">
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Tax Price
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {orderDetails?.taxAmount?.toFixed(2) ? `${orderDetails?.taxAmount?.toFixed(2)}` : "N/A"}
              </p>
            </div>
            <div className=" flex w-1/2 flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Total Order Price (Including Tax)
              </p>
              <p
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                className="text-sm text-[#979797] font-normal">
                {totalOrderPrice(orderDetails?.orderPrice?.toFixed(2) , orderDetails?.taxAmount?.toFixed(2))}
              </p>
            </div>
            
          </div>
          <div className="h-fit flex flex-row justify-between gap-8">
            <div className="w-full flex text-lg">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Products Details
              </p>
            </div>
          </div>
          {itemDetails?.map((item, index) => (
            <div
              key={item?._id}
              className="h-fit flex flex-col justify-between gap-5">
              <div className="w-full flex">
                <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                  {`Product ${index + 1}`}
                </p>
              </div>
              <div className="flex flex-row gap-8 bg-indigo-500 bg-opacity-20 rounded-xl border-2 border-[#524CBB] py-3 pl-3 pr-[20px]">
                <div className="h-fit w-1/2 flex flex-col justify-between gap-4">
                  <div className=" flex w-full flex-col gap-2">
                    <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                      Product Name
                    </p>
                    <p
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                      className="text-sm text-[#979797] font-normal">
                      {item?.productDetails?.productName}
                    </p>
                  </div>

                  <div className="flex w-full flex-col gap-2">
                    <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                      Quantity
                    </p>
                    <p
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                      className="text-sm text-[#979797] font-normal">
                      {item?.quantity}
                    </p>
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                      Price
                    </p>
                    <p
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                      className="text-sm text-[#979797] font-normal">
                      {`$${item?.itemPrice}`}
                    </p>
                  </div>
                  <div className=" flex w-full flex-col gap-2">
                    <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                      Slogan
                    </p>
                    <p
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                      className="text-sm text-[#979797] font-normal">
                      {item?.slogan1}
                    </p>
                  </div>
                </div>
                <div className="h-fit w-1/2 flex flex-col justify-between gap-3">
                  <div className=" flex w-full flex-col  ">
                    <div className="flex flex-row gap-4 mb-2 items-center ">
                      <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                        QR Code
                      </p>
                      <button type="button" onClick={handleDownloadQRCode} >
                        <DownloadForOfflineIcon style={{ color: "#888888", opacity: "0.8", width: "28px", height: "28px" }} />
                      </button>
                    </div>
                    <p
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                      className="text-sm text-[#979797] font-normal  break-all">
                      {item?.qrLink}
                    </p>
                    <img src={item?.qrLink} alt="" className="w-[200px] h-[200px] mt-4" />

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

      )}
    </>
  );
};
ViewOrder.propTypes = {
  orderId: PropTypes.string,
  setIsViewOpen: PropTypes.func,
};

export default ViewOrder;
