import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export const getChatList = (
  setLoading,
  setChatList,
  setQueryId,
  setUnreadCount,
  setIsClosed
) => {
  const unsubscribe = onSnapshot(
    query(collection(db, "rooms"), orderBy("last_message.createdAt", "desc")),
    (snapshot) => {
      const chatList = [];
      const queryIds = [];
      const unreadCounts = [];
      const closedChats = [];
      const promises = [];

      snapshot.forEach((doc) => {
        const roomData = doc.data();
        const roomId = roomData?.roomId;
        const queryId = roomData?.queryID;
        const unreadCount = roomData?.usersReadDetails[0]?.unreadCount || 0;
        const isClosed = roomData?.isClosed || false;
        const existingRoomIndex = chatList.findIndex(
          (room) => room.roomId === roomId
        );
        if (existingRoomIndex === -1) {
          chatList.push(roomData);
          queryIds.push(queryId);
          unreadCounts.push(unreadCount);
          closedChats.push(isClosed);
        }
      });

      Promise.all(promises)
        .then(() => {
          setChatList(chatList);
          setQueryId(queryIds);
          setUnreadCount(unreadCounts);
          setIsClosed(closedChats);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Error fetching messages:", {
            autoClose: 5000,
            toastId: "error setting first message",
          });
          setLoading(false);
        });
    }
  );

  return unsubscribe;
};

export const addMessageToRoom = async (roomId, userId, adminId, message) => {
  try {
    if (!adminId || message.trim() === "") {
      return;
    }
    const messageId = uuidv4();
    const messageData = {
      _id: messageId,
      text: message,
      createdAt: serverTimestamp(),
      receiverId: userId,
      senderId: adminId,
      user: { _id: adminId },
    };
    const messagesCollectionRef = collection(
      doc(db, "rooms", roomId),
      "messages"
    );
    await addDoc(messagesCollectionRef, messageData);
    const roomRef = doc(db, "rooms", roomId);
    await updateDoc(roomRef, {
      last_message: {
        message: message.trim(),
        createdAt: serverTimestamp(),
        userId: adminId,
      },
    });
  } catch (error) {
    toast.error("Error sending message:", {
      autoClose: 5000,
      toastId: "error sending message",
    });
  }
};
export const updateUnreadCount = async (
  roomId,
  adminId,
  userId,
  unreadArray
) => {
  const roomDocRef = doc(db, "rooms", roomId);
  const roomDocSnapshot = await getDoc(roomDocRef);
  if (roomDocSnapshot.exists()) {
    const roomData = roomDocSnapshot.data();
    const usersReadDetails = roomData.usersReadDetails.map((userDetails) => {
      if (userDetails.adminId === adminId) {
        userDetails.unreadCount = unreadArray[0];
      } else if (userDetails.userId === userId) {
        userDetails.unreadCount = unreadArray[1];
      }
      return userDetails;
    });

    await updateDoc(roomDocRef, {
      usersReadDetails: usersReadDetails,
    });
  }
};

export const updateStatus = async (roomId, status) => {
  try {
    const roomRef = doc(db, "rooms", roomId);
    await updateDoc(roomRef, {
      isClosed: status,
    });
  } catch (error) {
    toast.error("Error updating chat status", {
      autoClose: 5000,
      toastId: "error updating chat status",
    });
  }
};

export const listenForRealtimeMessages = (roomId, callback) => {
  const messagesRef = collection(db, "rooms", roomId, "messages");
  const orderedMessagesRef = query(messagesRef, orderBy("createdAt", "asc"));

  return onSnapshot(orderedMessagesRef, (snapshot) => {
    const messages = [];
    snapshot.forEach((doc) => {
      messages.push(doc.data());
    });
    callback(messages);
  });
};
