import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { listRevData } from "../../../API/reviewManagementApi";
import { motion } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from "react-moment";
import { toast } from "react-toastify";
const fadeInMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 1 },
};

const Tablee = () => {
    const tableHeading = ["Date and Time", "Name of Give Thanks", "Name of Get Thanks", "Emoticons", "Comments"];
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchUserData = async () => {
        try {
            const response = await listRevData(5, 1);
            setTableData(response?.data?.data?.reviewManagementData);
        } catch (error) {
            toast.error("Error fetching Review Management List", {
                autoClose: 5000, toastId: "dtgsgz"
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <TableContainer className='h-full p-5 flex flex-col gap-6' style={{ borderRadius: "12px" }} component={Paper}>
                <div className=" w-full h-full flex justify-between">
                    <div className="flex flex-col content1 h-full w-auto">
                        <p className="text-[18px] font-[700] text-black" style={{ fontFamily: "Plus Jakarta Sans" }}>Recent reviews / Feedback posted</p>
                        <p className="text-[12px] font-[400] text-[#888888]" style={{ fontFamily: "Plus Jakarta Sans" }}>List of recent reviews/feedback posted</p>
                    </div>
                    <Link to='/review_management'>
                        <div className="content2 mt-[10px] h-[18px] text-[#524CBB] font-[600] text-[14px]" style={{ fontFamily: "Plus Jakarta Sans" }}>
                            <button type="button" className='underline'>View All</button>
                        </div>
                    </Link>
                </div>
                {loading ? (
                    <div className="flex justify-center items-center mt-[50px] mb-[50px]">
                        <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
                    </div>
                ) : (
                    <Table aria-label="simple table h-[304px]">
                        <TableHead>
                            <TableRow>
                                {tableHeading.map((heading) => (
                                    <TableCell key={heading}
                                        line-height={"20px"} style={{ width: '253.2px' }}>
                                        <p className='text-[14px] font-[600] text-[#888888]' style={{ fontFamily: "Plus Jakarta Sans" }}>{heading}</p>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row) => (
                                <TableRow
                                    key={row?._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Moment format="DD MMM YYYY hh:mm A" >
                                            {row?.createdAt}
                                        </Moment>
                                    </TableCell>

                                    <TableCell align="left" style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}>
                                        <motion.p {...fadeInMotion}>
                                            {row?.giveThanksDetails?.userName}
                                            {!row?.giveThanksDetails?.userName && (
                                                <motion.p {...fadeInMotion} className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans", fontStyle: "italic" }}>
                                                    Deleted User
                                                </motion.p>
                                            )}
                                        </motion.p>
                                    </TableCell>

                                    <TableCell align="left" style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}>
                                        <motion.p {...fadeInMotion}>
                                            {row?.getThanksDetails?.userName}
                                            {!row?.getThanksDetails?.userName && (
                                                <motion.p {...fadeInMotion} className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans", fontStyle: "italic" }}>
                                                    Deleted User
                                                </motion.p>
                                            )}
                                        </motion.p>
                                    </TableCell>

                                    <TableCell align="left" style={{ fontFamily: "Plus Jakarta Sans" }}>
                                        {(row?.icon === undefined || !row?.icon) && (
                                            <motion.p {...fadeInMotion} className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>
                                                N/A
                                            </motion.p>
                                        )}
                                        {row?.icon === 'RECOGNITION' && '🤝 Recognition'}
                                        {row?.icon === 'CELEBRATION' && '🙏 Gratitude'}
                                        {row?.icon === 'GRATITUDE' && '🙌 Celebration'}
                                        {row?.icon === 'APPRECIATION' && '👍 Appreciation'}
                                    </TableCell>

                                    <TableCell align="left" style={{ maxWidth: '171px', textAlign: 'justify', fontFamily: "Plus Jakarta Sans" }}>
                                        <motion.p {...fadeInMotion} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%" }}>
                                            {row?.sayThanks ? row?.sayThanks : (
                                                <motion.p {...fadeInMotion} className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>
                                                    N/A
                                                </motion.p>
                                            )}
                                        </motion.p>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </motion.div>
    );
}

export default Tablee;
