import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Block from "../../../../../Assets/images/icons/block.png";
import Unblock from "../../../../../Assets/images/icons/unblock_grey.png";
import Eye from "../../../../../Assets/images/icons/eye.png";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Moment from "react-moment";
import GenericModal from "../../../../GenericModal";
import ViewQuery from "./view_received_query";
import { queriedUsers } from "../../../../API/manageCollabApi";
import { toast } from "react-toastify";
import CloseReceivedQuery from "./close_recieved_query";
const tableHeading = [
  "S. No.",
  "Name",
  "Profile Type",
  "Date and Time",
  "Email",
  "Reason",
  "Action",
];

export default function QueriesReceived() {
  const [loading, setLoading] = useState(true);
  const [viewAll, setViewAll] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [viewData, setViewData] = useState({});
  const [isClosedModalOpen, setIsClosedModalOpen] = useState(false);
  const [action, setAction] = useState(false);
  const handleQueryData = () => {
    setLoading(true);
    if (viewAll) {
      queriedUsers(page, limit)
        .then((response) => {
          setTableData(response?.data?.data?.queryList);
          setTotalCount(response?.data?.data?.totalQueryCount);
        })
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "error fetching query list",
          })
        )
        .finally(() => setLoading(false));
    } else {
      queriedUsers(1, 5)
        .then((response) => setTableData(response?.data?.data?.queryList))
        .catch((error) =>
          toast.error(error?.message, {
            autoClose: 5000,
            toastId: "error fetching query list",
          })
        )
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (action) {
      handleQueryData();
    }
  }, [action]);
  useEffect(() => {
    handleQueryData();
  }, [viewAll, page, limit]);

  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const limitOptions = [10, 25, 50, 75, 100];
  const handleView = (data) => {
    setViewData(data);
    setIsViewModalOpen(!isViewModalOpen);
  };

  const handleCloseQuery = (data) => {
    setAction(false);
    setViewData(data);
    setIsClosedModalOpen(!isClosedModalOpen);
  };
  const handleSendEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };
  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );
  const renderActionButtons = (row) => (
    <div className="flex gap-2">
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        onClick={() => handleView(row)}>
        <img src={Eye} alt="" className="w-5 h-5" />
      </button>
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        style={{ opacity: row?.userDetails?.userName ? "1" : "0.5" }}
        disabled={!row?.userDetails?.userName}
        onClick={() => handleCloseQuery(row)}>
        {row?.isClosed ? (
          <img src={Unblock} alt="" className="w-5 h-5" />
        ) : (
          <img src={Block} alt="" className="w-5 h-5" />
        )}
      </button>
    </div>
  );
  const renderEmailButton = (email, isClose, user) => {
    return (
      <button
        className=" text-[#524CBB] !bg-[#EEEEEE] text-[14px] font-bold rounded-[6px] px-[16px] py-[10px]"
        style={isClose || !user ? { color: "#888888" } : {}}
        type="button"
        disabled={isClose || !user}
        onClick={() => handleSendEmail(email)}>
        Send Email
      </button>
    );
  };

  return (
    <Paper
      className="randomClass1 flex-grow h-[inherit] flex flex-col justify-between gap-6 p-6 mb-6"
      style={{ borderRadius: "12px", justifyContent: "flex-start" }}
      component={Paper}>
      <div className="flex justify-between" style={{ alignItems: "baseline" }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Queries Received
        </p>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}

      {!loading && (
        <TableContainer className="card w-auto overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          key={heading}
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[194px] h-13">
                      {renderTableCell(<p>{index + 1}</p>)}
                      {renderTableCell(
                        row?.userDetails?.userName ||
                          renderTextContent("Deleted User", {
                            fontStyle: "italic",
                          })
                      )}
                      {renderTableCell("Get Thanks")}
                      {renderTableCell(
                        <Moment format="DD MMM YYYY ,  hh:mm A">
                          {row?.createdAt}
                        </Moment>
                      )}
                      {renderTableCell(
                        renderEmailButton(
                          row?.email,
                          row?.isClosed,
                          row?.userDetails?.userName
                        )
                      )}
                      {renderTableCell(
                        row?.typeOfCollabReq || renderTextContent("N/A")
                      )}

                      {renderTableCell(renderActionButtons(row))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}

      {isViewModalOpen && (
        <GenericModal
          open={isViewModalOpen}
          handleClose={() => setIsViewModalOpen(false)}
          title="Message"
          content={
            <ViewQuery
              queryData={viewData}
              setIsModalOpen={setIsViewModalOpen}
            />
          }
          isCross={true}
          width="631px"
          height="385px"
        />
      )}
      {isClosedModalOpen && (
        <GenericModal
          open={isClosedModalOpen}
          handleClose={() => setIsClosedModalOpen(false)}
          content={
            <CloseReceivedQuery
              setIsModalOpen={setIsClosedModalOpen}
              setIsClose={setAction}
              userData={viewData}
            />
          }
          isCross={true}
          width="391px"
          height="323px"
        />
      )}
      {viewAll ? (
        <div className="flex justify-between mt-auto">
          <Select
            labelId="pagination-limit-select-label"
            value={limit}
            onChange={handlePaginationLimitChange}
            className="w-[66px] h-11 bg-[#EEEEEE] "
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "500",
              width: "fit-content",
            }}>
            {limitOptions.map((options) => (
              <MenuItem
                key={options}
                style={{
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                }}
                value={options}>
                {options}
              </MenuItem>
            ))}
          </Select>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(totalCount / limit)}
              defaultPage={1}
              siblingCount={3}
              shape="rounded"
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      ) : (
        <div className="mt-auto">
          <button
            className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]"
            type="button"
            onClick={handleViewAllClick}>
            View All
          </button>
        </div>
      )}
    </Paper>
  );
}
