import React, { useState } from "react";
import Notification from "../../../Assets/images/icons/notification.png";
import Badge from "@mui/material/Badge";
import ArrowDown from "../../../Assets/images/icons/arrow-down.png";
import ProfilePopUp from "./profilePopUp";
import User from "../../../Assets/images/icons/user.png";
import Popover from "@mui/material/Popover";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clickCount, setClickCount] = useState(0);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotification = (e) => {
    setClickCount(clickCount + 1)
    navigate("/notifications", {
      state: {
        eventTriggered: clickCount
      },
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const userDetails = useSelector((state) => state?.user)
  return (
    <div className="w-full z-10 right-0 h-16 px-6 py-3 bg-white shadow justify-end items-start gap-3 inline-flex">
      <div className="justify-center items-center gap-5 flex">
        <div className="w-8 h-8 justify-center items-center flex">

          <div className="w-8 h-8 relative">
            <Badge
              color="primary"
              onClick={(e) => {
                handleNotification(e);
              }}
            >
              <img src={Notification} alt="" />
            </Badge>
          </div>
        </div>
        <button type="button"
          className="justify-center items-center gap-3 flex cursor-pointer"
          aria-describedby={id}
          onClick={handleClick}
        >
          <div className="bg-gray-300 w-10 h-10 rounded-full">
            <img className="w-[inherit] h-[inherit] rounded-full" src={userDetails?.profileImg || User} alt="" />
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-black text-base font-semibold font-['Plus Jakarta Sans']">
              {userDetails?.fullName || "Super admin"}
            </div>
            <div className="text-zinc-500 text-sm font-normal font-['Plus Jakarta Sans']">
              {userDetails?.email}
            </div>
          </div>
          <div className="w-6 h-6 justify-center items-center flex">
            <div className="w-6 h-6 relative">
              <img src={ArrowDown} alt="arrow icon" />
            </div>
          </div>
        </button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-3"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ProfilePopUp
        />
      </Popover>
    </div>
  );
};
export default Navbar;
