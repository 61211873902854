import React, { useEffect, useState } from 'react'
import "./profile_details.css";
import { toast } from 'react-toastify';
import { editProfileDetails, profileDetails, updatePassword } from '../../../API/myProfileApi';
import Joi from 'joi';
import PersonIcon from '@mui/icons-material/Person';
import ModeEdit from "@mui/icons-material/ModeEdit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Profile_details = () => {

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [isExpanded, setIsExpanded] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [name, setName] = useState(profileData?.fullName);
    const [image, setImage] = useState(null);
    const [profilePicture, setProfilePicture] = useState(profileData?.profileImage || "");
    const [errors, setErrors] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCnfrmPassword, setShowCnfrmPassword] = useState(false);
    const passwordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const newPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const cnfrmPasswordVisibility = () => {
        setShowCnfrmPassword(!showCnfrmPassword);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const passwordChangeValidation = Joi.object({
        currentPassword: Joi.string().required().label('Current Password'),
        newPassword: Joi.string()
            .min(8)
            .max(16)
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/)
            .required()
            .label('New Password')
            .messages({
                "string.empty": "New Password cannot be empty",
                "string.pattern.base":
                    "The password must include, a minimum length of 8 characters, which should include a mix of upper case, lower case letters, numbers, and special characters.",
            }),
        confirmPassword: Joi.string()
            .valid(Joi.ref('newPassword'))
            .required()
            .label('Confirm Password')
            .messages({
                'any.only': '{{#label}} does not match with New Password',
            }),
    });
    const handleChangeProfilePicture = (e) => {

        if (e.target.files[0]) {
            setErrors("");
            const maxSize = 5 * 1024 * 1024;
            if (e.target.files[0].size <= maxSize) {

                setProfilePicture(e.target.files[0]);
                setImage(URL.createObjectURL(e.target.files[0]));
            } else {
                setProfilePicture(null);
                setErrors("File size exceeds the maximum size allowed (5 MB). Please choose a smaller file.");
                return;
            }
        }
    };

    const handleCancelPassword = () => {
        setIsExpanded(false);
    }
    const handleCancel = () => {
        setErrors("")
        setIsProfile(false);
        image ? setImage(profileData?.profileImage) : setImage(null)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const postForm = {
            currentPassword: formData?.currentPassword,
            password: formData?.newPassword,
            confirmPassword: formData?.confirmPassword,
        }
        const validData = passwordChangeValidation.validate(formData);
        if (validData?.error?.message) {
            toast.error(validData?.error?.message, {
                autoClose: 5000,
                toastId: "453"
            })
            return;
        }
        updatePassword(postForm)
            .then((response) => {
                toast.success("Password updated succesfully!", {
                    autoClose: 5000, toastId: "12343"
                })
                setIsExpanded(false)
            })
            .catch((error) => {
                toast.error(error?.message, {
                    autoClose: 5000,
                    toastId: "12323"
                })
            })
    };
    const handleNameChange = (e) => {
        setName(e?.target?.value)
    }
    const handleProfileUpdate = () => {
        let data = new FormData();
        data.append("fullName", name || profileData?.fullName);
        if (profilePicture) { data.append("media", profilePicture) };
        data.append("email", profileData?.email);
        editProfileDetails(data)
            .then((response) => {
                toast.success("Profile updated succesfully!", {
                    autoClose: 5000, toastId: "12343"
                })
                setIsProfile(false);
                setProfilePicture(null);
                fetchProfileDetails();
            })
            .catch((error) => {
                toast.error(error?.message, {
                    autoClose: 5000,
                    toastId: "12323"
                })
            })
    }

    const fetchProfileDetails = () => {
        profileDetails()
            .then((response) => {
                setProfileData(response?.data?.data[0]);
                setImage(response?.data?.data[0]?.profileImage);
            })
            .catch((error) => toast.error(error?.message, {
                autoClose: 5000,
                toastId: "ryb"
            }))
    }
    useEffect(() => {
        fetchProfileDetails();
    }, [])


    return (
        <div className='h-auto p-6 flex flex-col gap-5 font-semibold'>

            <hr className="w-full h-[1px] bg-[#efefef]" />
            <div className=' flex flex-row justify-between items-start'>
                <div className='relative w-[135px] h-[135px] rounded-full bg-gray-300' >
                    {image ? (
                        <img
                            src={image}
                            alt=''
                            className='w-[inherit] h-[inherit] rounded-full'
                        />


                    ) : (
                        <PersonIcon style={{ fontSize: '135px', color: 'white' }} />
                    )}

                    {isProfile && (
                        <button type='button' className='flex absolute top-0 right-0 cursor-pointer bg-[#EEEEEE] rounded-[6px] h-[30px] w-[30px] justify-center items-center' onClick={() => {
                            document.getElementById("change-profile-picture").click();
                        }}>
                            <ModeEdit style={{ fontSize: '20px', color: '#888888' }} />
                            <input
                                type="file"
                                accept="image/png,  image/jpeg, image/jpg"
                                id="change-profile-picture"
                                style={{ display: "none" }}
                                onChange={handleChangeProfilePicture}
                            />

                        </button>

                    )}

                </div>
                {isProfile && <div className="flex flex-row justify-between items-center gap-4 ">
                    <button className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]" type="submit" onClick={handleCancel}>Cancel
                    </button><button className="btn text-[14px] font-medium flex flex-row justify-center items-center gap-1" style={errors ? { color: "#888888", background: "#EEEEEE" } : {}} type="submit" disabled={errors} onClick={handleProfileUpdate}>Save Profile
                    </button>
                </div>}
                {!isProfile && <button className="btn text-[14px] font-medium flex flex-row justify-center items-center gap-1" onClick={() => setIsProfile(true)}>
                    <ModeEdit className="w-5 h-5 font-[white] " />
                    <span className="text-[14px] font-medium">Update Profile</span>
                </button>}
            </div>
            {errors && <div><p className="text-[#d32f2f] font-[400]">{errors}</p></div>}
            <hr className="w-full h-[1px] bg-[#efefef]" />
            <div className="flex flex-col gap-8">
                <div className="flex flex-row justify-between ">
                    <div className="w-1/2">
                        <p className="text-4">Full Name</p>
                        {!isProfile ? <p className="text-[14px] text-grey-500 font-medium">{profileData?.fullName}</p>
                            : <input
                                type="fullName"
                                id="fullName"
                                name="fullName"
                                className="w-[80%] h-[90%] border-[1px] border-[#C7C6C6] font-normal rounded-md p-[5px]"
                                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                defaultValue={profileData?.fullName}
                                onChange={handleNameChange}
                            />}
                    </div>
                    <div className="w-1/2">
                        <p className="text-4">Email Address</p>
                        <p className="text-[14px] text-grey-500 font-medium">{profileData?.email}</p>
                    </div>
                </div>
                {!isExpanded && (
                    <div className="flex flex-row justify-between items-center">
                        <div className="">
                            <p className="text-4">Password</p>
                            <p className="text-[14px] text-grey-500 font-medium">*****</p>
                        </div>
                        <button className="btn flex flex-row justify-center items-center gap-1" onClick={() => setIsExpanded(true)}>
                            <ModeEdit className="w-5 h-5 font-[white] " />
                            <span className="text-[14px] font-medium">Update Password</span>
                        </button>
                    </div>
                )}
                {isExpanded && (
                    <>
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-4">Update Password</p>
                            <div className="flex flex-row justify-between items-center gap-4 ">  <button className="btn !text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px]" type="submit" onClick={handleCancelPassword}>Cancel
                            </button><button className="btn text-[14px] font-medium flex flex-row justify-center items-center gap-1" type="submit" onClick={(e) => handleSubmit(e)}>Save Password
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col gap-6">
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">Current Password</p>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="currentPassword"
                                    name="currentPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px] pr-[50px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="Current Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={passwordVisibility}>
                                    {showPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">New Password</p>
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    id="newPassword"
                                    name="newPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px] pr-[50px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="New Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={newPasswordVisibility}>
                                    {showNewPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                            <div className="w-1/2 flex flex-col relative">
                                <p className="">Confirm New Password</p>
                                <input
                                    type={showCnfrmPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="w-full border-[1px] border-[#C7C6C6] font-normal rounded-md p-[10px] pr-[50px]"
                                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                                    placeholder="Confirm Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button type="button" className="flex absolute right-[12px] top-[36px]" onClick={cnfrmPasswordVisibility}>
                                    {showCnfrmPassword ? (
                                        <VisibilityIcon sx={{ color: "#a1a0a3" }} />
                                    ) : (
                                        <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
                                    )}                </button>

                            </div>
                        </div>
                    </>
                )}
            </div>

        </div>
    );
}

export default Profile_details
