import React from 'react';
import Trash from '../../../../Assets/images/icons/trash_white.png';
import { delAdmin } from '../../../API/manageAdminApi';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const DeleteAdmin = ({ isRole, setIsDeleted, userId, setIsOpenModal, userType }) => {
    const handleClose = () => {
        setIsOpenModal(false);
    };

    const handleClick = async () => {
        delAdmin({ _id: userId, userType })
            .then((res) => {
                setIsDeleted(true);
                toast.success('Deleted successfully', {
                    autoClose: 5000,
                    toastId: 'gbsef',
                });
                handleClose();
            })
            .catch((error) =>
                toast.error(error?.message, {
                    autoClose: 5000,
                    toastId: 'grgf',
                })
            );
    };

    return (
        <div className="w-[323px] h-auto flex flex-col gap-5 font-semibold">
            <div className="flex flex-col w-[343px] h-33 gap-6 m-auto">
                <div className="gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#D33939]">
                    <img src={Trash} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" />
                </div>
                <div>
                    <p className="font-[Plus Jakarta Sans] font-bold text-5 text-center w-fit h-auto">
                        Are you sure you want to delete this {isRole === 1 ? 'Admin' : 'Sub Admin'}?
                    </p>
                    <p className="font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]">
                        After this {isRole === 1 ? 'Admin' : 'Sub Admin'} is deleted, they won't be able to use the
                        application. Are you sure you want to delete them?
                    </p>
                </div>
            </div>
            <div className="flex gap-4 w-[162px] h-11 mt-12 m-auto">
                <button className="w-[116px] bg-[#EEEEEE] rounded-[6px]" onClick={handleClose}>
                    Cancel
                </button>
                <button className="w-[59px] bg-[#524CBB] rounded-[6px] text-white" onClick={handleClick}>
                    Yes
                </button>
            </div>
        </div>
    );
};

DeleteAdmin.propTypes = {
    isRole: PropTypes.number,
    setIsDeleted: PropTypes.func,
    userId: PropTypes.string,
    setIsOpenModal: PropTypes.func,
    userType: PropTypes.string,
};

export default DeleteAdmin;
