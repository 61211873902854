import React from 'react';
import Block from "../../../../Assets/images/icons/block_red.png";
import Unblock from "../../../../Assets/images/icons/unblock.png";
import { blockAdmin } from '../../../API/manageAdminApi';
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
const AdminBlock = ({ yes, isRole, isBlocked, setIsBlocked, userId, setIsOpenModal }) => {
    const handleClick = async () => {
        const data = {
            isBlocked: !yes,
            userId: userId,
            userType: "admin"
        };
        blockAdmin(data)
            .then((response) => {
                setIsBlocked(true);
                const isAdmin = isRole === 1;
                const isUnblocked = yes;
                const action = isUnblocked ? "unblocked" : "blocked";
                const roleType = isAdmin ? "Admin" : "Sub Admin";
                const message = `${roleType} ${action} Successfully`;

                toast.success(message, {
                    autoClose: 5000,
                    toastId: "adminBlockToast"
                });
                handleClose();


            })

            .catch((error) => toast.error(error?.message, {
                autoClose: 5000,
                toastId: "adminBlockErrorToast"
            }));
    };

    const handleClose = () => {
        setIsOpenModal(false);
    };

    return (
        <div className='w-[323px] h-auto flex flex-col gap-5 font-semibold'>
            <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">
                <div className={`gap-5 m-auto w-[78px] h-[78px] rounded-full bg-${!isBlocked && !yes ? 'FFF1F1' : 'E1FFD7'}`}>
                    <img src={!isBlocked && !yes ? Block : Unblock} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" />
                </div>
                <div>
                    <p className='font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] h-[30px]'>Are you sure you want to {!isBlocked && !yes ? "block" : "unblock"} </p>
                    <p className='font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]'>
                        {`Are you certain you want to ${!isBlocked && !yes ? "prevent access to the Application for this" : "provide access to the Application for this"} ${isRole === 1 ? "Admin" : "Sub Admin"} by ${!isBlocked && !yes ? "blocking" : "unblocking"} them?`}
                    </p>
                </div>
            </div>
            <div className="flex gap-4 w-[162px] h-11 mt-12 m-auto">
                <button className='w-[87px] bg-[#EEEEEE] rounded-[6px]' onClick={handleClose}>Cancel</button>
                <button className='w-[59px] bg-[#524CBB] rounded-[6px] text-white' onClick={handleClick}>Yes</button>
            </div>
        </div>
    );
};

AdminBlock.propTypes = {
    yes: PropTypes.bool,
    isRole: PropTypes.number,
    isBlocked: PropTypes.bool,
    setIsBlocked: PropTypes.func,
    userId: PropTypes.string,
    setIsOpenModal: PropTypes.func,
};

export default AdminBlock;
