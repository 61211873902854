import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: "",
  email: "",
  role: "",
  profileImg: "",
  id: "",
  permissions: "",
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeUser(state, action) {
      state.fullName = action.payload?.fullName || action.payload?.userName;
      state.email = action.payload?.email;
      state.role = action.payload?.role;
      state.profileImg =
        action.payload?.profileImage || action.payload?.profilePicture;
      state.id = action.payload?._id || action.payload?.id;
      state.permissions = action.payload?.permissions;
      return state;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
