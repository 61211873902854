import React from "react";
import Block from "../../../../../Assets/images/icons/block_red.png";
import Unblock from "../../../../../Assets/images/icons/unblock.png";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { queryStatus } from "../../../../API/gratiSupportApi";

const CloseReceivedQuery = ({ setIsClose, userData, setIsModalOpen }) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCloseQuery = () => {
    queryStatus({ queryID: userData?.queryID, isClosed: !userData?.isClosed })
      .then((response) => {
        if (response?.data?.data) {
          toast.success(
            response?.data?.data?.isClosed
              ? "Query Closed Successfully"
              : "Query Opened Succesfully",
            {
              autoClose: 5000,
              toastId: "Closing query ",
            }
          );
          setIsModalOpen(false);
          setIsClose(true);
        } else {
          toast.error("Error closing query", {
            autoClose: 5000,
            toastId: "error closing received query",
          });
        }
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "status closed error",
        })
      );
  };
  return (
    <div className="h-auto flex flex-col gap-5 font-semibold">
      <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">
        <div className=" gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#FFF1F1]">
          <img
            src={userData?.isClosed ? Unblock : Block}
            alt=""
            className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]"
          />
        </div>
        <p className="font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] ">
          {userData?.isClosed
            ? "Are you sure you want to open this Query?"
            : "Are you sure you want to close this Query?"}
        </p>
        <p className="font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px]">
          {userData?.isClosed
            ? "Do you want to open this query?"
            : "Make sure this query is answered before you decide to close it."}
        </p>
        <div className="flex gap-4 w-[162px] h-11  m-auto">
          <button
            className="w-[87px] bg-[#EEEEEE] rounded-[6px] "
            onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="w-[59px] bg-[#524CBB] rounded-[6px] text-white"
            onClick={handleCloseQuery}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

CloseReceivedQuery.propTypes = {
  setIsClose: PropTypes.func,
  userData: PropTypes.object,
  setIsModalOpen: PropTypes.func,
};
export default CloseReceivedQuery;
