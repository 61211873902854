import React from 'react';
import Block from "../../../../Assets/images/icons/block_red.png";
import Unblock from "../../../../Assets/images/icons/unblock.png";
import { blockAdmin } from '../../../API/manageAdminApi';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
const UserBlock = ({ setIsBlocked, userId, userType, setIsOpenModal, yes }) => {



    const handleClick = async () => {
        const data = {
            isBlocked: !yes,
            userId: userId,
            userType
        }
        blockAdmin(data)
            .then((res) => {
                setIsBlocked(true);
                handleClose();
                if (!yes)
                    toast.success("User Blocked Successfully", {
                        autoClose: 5000, toastId: "353"
                    })
                else {
                    toast.success("User Unblocked Successfully", {
                        autoClose: 5000, toastId: "dgd4"
                    })
                }
            }).catch((error) => toast.error(error?.message, {
                autoClose: 5000, toastId: "eertw4"
            }))


    }


    const handleClose = () => {
        setIsOpenModal(false)

    };

    return (
        <div className='w-[323px] h-auto flex flex-col gap-5 font-semibold'>
            <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">


                {!yes ?
                    <div className=" gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#FFF1F1]"> <img src={Block} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" />
                    </div>
                    :
                    <div className=" gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#E1FFD7]"><img src={Unblock} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" /> </div>}
                <div>
                    <p className='font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] h-[30px]'>Are you sure you want to  {!yes ? "block" : "unblock"} </p>

                    {!yes ?
                        <p className='font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]'>Are you certain you want to prevent access to the Application for this user by blocking them?</p> :
                        <p className='font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]'>Are you certain you want to unblock this user?</p>
                    }
                </div>
            </div>
            <div className={yes ? "flex gap-4 w-[162px] h-11 m-auto" : "flex gap-4 w-[162px] h-11  mt-8 m-auto"}>
                <button className='w-[87px] bg-[#EEEEEE] rounded-[6px] ' onClick={handleClose} > Cancel</button>
                <button className='w-[59px] bg-[#524CBB] rounded-[6px] text-white' onClick={() => handleClick()}> Yes</button>
            </div>
        </div>
    );


}
UserBlock.propTypes = {
    setIsBlocked: PropTypes.func,
    userId: PropTypes.string,
    userType: PropTypes.string,
    setIsOpenModal: PropTypes.func,
    yes: PropTypes.bool,
};
export default UserBlock