import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const queriedUsers = (pageNo, limit) => {
    return wrappedFetch(`${API.QUERIED_USERS}?pageNo=${pageNo}&limit=${limit}`, "GET");
}
export const createNewPostImage = (data) => {
    return wrappedFetch(API.CREATE_POST_IMAGE, "POST", data)
}
export const createNewPostVideo = (data) => {
    return wrappedFetch(API.CREATE_POST_VIDEO, "POST", data)
}
export const collabPosts = (pageNo, limit) => {
    return wrappedFetch(`${API.COLLAB_POST_DATA}?pageNo=${pageNo}&limit=${limit}`, "GET");
}
export const deleteCollabPost = (data) => {
    return wrappedFetch(API.DELETE_COLLAB_POST, "DELETE", data)
}

export const editCollabPostImage = (data) => {
    return wrappedFetch(API.EDIT_COLLAB_POST_IMAGE, "POST", data)
}

export const editCollabPostVideo = (data) => {
    return wrappedFetch(API.EDIT_COLLAB_POST_VIDEO, "POST", data)
}