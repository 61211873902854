import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const fetchOrder = (pageNo, limit) => {
    return wrappedFetch(`${API.GET_ORDERS}?pageNo=${pageNo}&limit=${limit}`, "GET");
}
export const fetchOrderDetails = (_id) => {
    return wrappedFetch(`${API.ORDER_DETAILS}?_id=${_id}`, "GET");
}

export const updateOrderStatus = (_id, data) => {
    return wrappedFetch(`${API.ORDER_STATUS_UPDATE}/${_id}`, "PUT", data);
}

export const searchOrderById = (orderId) => {
    return wrappedFetch(`${API.SEARCH_ORDER}?orderId=${orderId}`, "GET");
}

export const downloadUserQR = (_id) => {
    return wrappedFetch(`${API.DOWNLOAD_USER_QR}/${_id}`, "GET");
}