import React, { useState } from "react";
import PropTypes from 'prop-types';
import Circle from "../../../../Assets/images/icons/tick-circle.png";
import { TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import "./viewReview.css";
import GenericModal from "../../../GenericModal";
import EditReview from "./editReview";
import DeleteReview from "./deleteReview";
import Moment from "react-moment";

const ViewReview = ({ setIsModal, data, permission, selectedButton }) => {
  const CHARACTER_LIMIT = 250;

  const [isEdit, setIsEdit] = useState(false);
  const [isDel, setIsDel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsDel(!isDel);
    setIsEdit(!isEdit);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDel(!isDel);
    setIsEdit(!isEdit);
  };

  const handleClick = () => {
    handleOpenModal();
    setIsEdit(!isEdit);
    setIsDel(false);
  };

  const handleDelete = () => {
    handleOpenModal();
    setIsDel(!isDel);
    setIsEdit(false);
  };

  const title = !isEdit ? ["Review Details"] : ["Edit Review Details"];

  const reviewIcon = {
    RECOGNITION: '🤝 Recognition',
    CELEBRATION: '🙌 Celebration',
    GRATITUDE: '🙏 Gratitude',
    APPRECIATION: '👍 Appreciation',
    undefined: "N/A"
  };
  const buttonSection =
    permission && selectedButton !== 2 ? (
      <div className="w-[232px] h-[45px] flex gap-[12px] mt-[45px] m-auto">
        <button className="w-[110px] rounded-[6px] py-[10px] px-4 bg-[#EEEEEE] text-[#888888] text-4 font-bold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }} onClick={handleDelete}>Delete</button>
        <button className={"w-[110px] rounded-[6px] py-[10px] px-4 bg-[#524CBB] text-white text-4 font-bold"} style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }} onClick={handleClick}>Edit</button>
      </div>
    ) : null;

  return (
    <div className="h-[fit] w-[583px] flex flex-col gap-6 font-semibold">
      <div className="h-[35px] flex justify-between">
        <p className="h-fit text-black text-[20px] font-bold self-center" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "24px" }}>
          {reviewIcon[data?.icon]}
        </p>
        <p className="w-fit h-5 text-black text-[14px] font-medium self-center" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "20px" }}>
          <Moment format="DD MMM YYYY , hh:mm A" >{data?.createdAt}</Moment>
        </p>
      </div>

      <div className="w-[583px] h-[260px] flex flex-col gap-2">
        <p className="w-[56px] h-[24px] text-black text-[16px] font-semi-bold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "24px" }}>
          Review
        </p>
        <div className="w-[583px] h-[204px] rounded-1 bg-[#EEEEEE] p-[12px] flex flex-col gap-[10px]">
          <TextField
            inputProps={{
              maxLength: CHARACTER_LIMIT,
              style: {
                maxHeight: "180px",
                overflow: "auto",
                height: "160px",
                fontFamily: "Plus Jakarta Sans,sans-serif",
                lineHeight: "20px",
                fontWeight: "500",
                fontStyle: "italic",
                fontSize: "14px",
                color: "#888888",
              },
            }}
            disabled
            multiline
            value={data?.sayThanks.split(/\s+/).filter(Boolean).join(' ')}
            margin="normal"
            className="w-full max-h-[180px]"
            style={{ margin: "0px" }}
          />
        </div>
        <div className="flex justify-end w-[583px] h-5">
          <FormHelperText
            className="h-[20px] w-fit"
            style={{
              maxHeight: "180px",
              overflow: "auto",
              height: "160px",
              fontFamily: "Plus Jakarta Sans,sans-serif",
              lineHeight: "20px",
              fontWeight: "500",
              fontStyle: "italic",
              fontSize: "14px",
              color: "#888888",
            }}
          >{`${data?.sayThanks.length}/${CHARACTER_LIMIT}`}</FormHelperText>
        </div>
      </div>

      <div className="w-[545px] h-[58px] flex gap-9">
        <div className="w-[260px] h-[58px] flex flex-col gap-1">
          <p className="w-fit h-6 text-black text-[20px] font-semi-bold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "24px" }}>
            Give Thanks
          </p>
          <div className="w-[125px] h-[30px] flex gap-1">
            <img
              src={data?.giveThanksDetails?.profilePicture || Circle}
              alt=""
              className="w-[30px] h-[30px] border-2 border-[#524CBB] rounded-full"
            />
            <p className="w-fit h-5 text-black text-[14px] font-medium self-center" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "20px" }}>
              {data?.giveThanksDetails?.userName || <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
            </p>
          </div>
        </div>

        <div className="w-[260px] h-[58px] flex flex-col gap-1">
          <p className="w-fit h-6 text-black text-[20px] font-semi-bold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "24px" }}>
            Get Thanks
          </p>
          <div className="w-[125px] h-[30px] flex gap-1">
            <img
              src={data?.getThanksDetails?.profilePicture || Circle}
              alt=""
              className="w-[30px] h-[30px] border-2 border-[#524CBB] rounded-full"
            />
            <p className="w-fit h-5 text-black text-[14px] font-medium self-center" style={{ fontFamily: "Plus Jakarta Sans,sans-serif", lineHeight: "20px" }}>
              {data?.getThanksDetails?.userName || <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
            </p>
          </div>
        </div>
      </div>

      {buttonSection}

      {isEdit && (
        <GenericModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          content={<EditReview setIsModal={setIsModal} setIsOpenModal={setIsModalOpen} data={data} />}
          isCross={true}
          title={title[0]}
          width="631px"
          height="610px"
        />
      )}

      {isDel && (
        <GenericModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          content={<DeleteReview setIsModal={setIsModal} setIsDelete={setIsDel} isDelete={isDel} setIsOpenModal={setIsModalOpen} data={data} />}
          isCross={true}
          title={title[0]}
          width="391px"
          height="368px"
        />
      )}
    </div>
  );
};

ViewReview.propTypes = {
  setIsModal: PropTypes.func,
  data: PropTypes.object,
  permission: PropTypes.bool,
  selectedButton: PropTypes.number,
};

export default ViewReview;
