import React, { useState } from "react";
import PropTypes from "prop-types";

const Attachments = ({ attachmentData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? attachmentData.length - 1 : prev - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) =>
      prev === attachmentData.length - 1 ? 0 : prev + 1
    );
  };

  const handleToggleZoom = () => {
    setZoomLevel((prev) => (prev === 1 ? 2 : 1));
  };

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      setZoomLevel((prev) => Math.min(prev + 0.1, 3));
    } else {
      setZoomLevel((prev) => Math.max(prev - 0.1, 1));
    }
  };

  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled = currentSlide === attachmentData.length - 1;

  return (
    <div
      className="bg-gray-200 w-full h-full relative overflow-hidden rounded-xl"
      onWheel={handleWheel}>
      {attachmentData.map((attachment, index) => (
        <button
          type="button"
          key={attachment}
          className={`absolute top-0 left-0 transition-transform duration-300 ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
          style={{
            transform: `translateX(${
              100 * (index - currentSlide)
            }%) scale(${zoomLevel})`,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: zoomLevel > 1 ? "zoom-out" : "zoom-in",
          }}
          onClick={handleToggleZoom}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleToggleZoom();
            }
          }}>
          {attachment.mimeType.startsWith("image/") ? (
            <img
              src={attachment.imgUrl}
              alt={`${index + 1}`}
              className="w-full h-full object-contain"
            />
          ) : (
            <video controls className="w-full h-full">
              <source src={attachment.imgUrl} type="video/mp4" />
              <track kind="captions" src="#" label="English" />
              Your browser does not support the video tag.
            </video>
          )}
        </button>
      ))}
      <button
        className={`absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full ${
          isPrevDisabled
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-gray-500 text-white"
        }`}
        onClick={handlePrevSlide}
        disabled={isPrevDisabled}
        tabIndex={0}>
        {"<"}
      </button>
      <button
        className={`absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full ${
          isNextDisabled
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-gray-500 text-white"
        }`}
        onClick={handleNextSlide}
        disabled={isNextDisabled}
        tabIndex={0}>
        {">"}
      </button>
    </div>
  );
};

Attachments.propTypes = {
  attachmentData: PropTypes.arrayOf(
    PropTypes.shape({
      mimeType: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ),
};

export default Attachments;
