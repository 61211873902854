import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Tab, Tabs } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../../../../Assets/images/icons/search.png";
import "../manage_admins/manageEdit.css";
import UserManagementTable from "./user_management_table";
import { useLocation } from "react-router-dom";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",

  },
}));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "16px",
    marginRight: "20px",
    color: "#888888",
    "&.Mui-selected": {
      color: "#524CBB",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#524CBB",
    },
  })
)

export default function UserManagement() {
  const { state } = useLocation();
  const getTypeFilter = (type) => {
    if (type === 2) {
      return "GIVETHANKS";
    } else if (type === 3) {
      return "INDIVIDUAL";
    } else {
      return "ALLTYPE";
    }
  };
  const getTypeLabel = (type) => {
    if (type === 2) {
      return "Give Thanks";
    } else if (type === 3) {
      return "Individual";
    } else {
      return "All Types";
    }
  }
  const [selectedTab, setSelectedTab] = useState(getTypeFilter(state?.type));
  const [selectedLabel, setSelectedLabel] = useState(getTypeLabel(state?.type));
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const handleChange = (event) => {
    setSelectedLabel("All Types");
    setSelectedTab("ALLTYPE");
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery(event?.target?.value);
  };
  const handleSearchClear = (event) => {
    setSelectedLabel("All Types");
    setSelectedTab("ALLTYPE");
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery("");
  };
  const handleTabChange = (event, newValue) => {
    setSearchQuery("");
    setSelectedLabel(event?.target?.textContent);
    setSelectedTab(newValue);
  };
  return (
    <div className="flex flex-col h-[100%] max-h-[87vh] justify-start gap-6 ">
      <Paper
        className="flex flex-col justify-between gap-6 p-6 pb-0"
        style={{ borderRadius: "12px", justifyContent: "flex-start" }}
        component={Paper}>
        <div
          className="flex justify-between "
          style={{
            alignItems: "center", alignContent: "center"
          }}
        >
          <p
            className="text-[18px] font-[700] text-black"
            style={{ fontFamily: "Plus Jakarta Sans" }}
          >
            User Management
          </p>

          <div
            className="flex gap-3 h-11 font-[600] text-[14px] "
            style={{ fontFamily: "Plus Jakarta Sans" }}
          >
            <Search className="flex flex-row justify-between relative !mr-0">
              <SearchIconWrapper>
                <img src={SearchIcon} alt="" className="w-[18px] h-[18px]" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Username / Business Name"
                value={searchQuery}
                onChange={handleChange}
                inputProps={{ "aria-label": "search" }}
                className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
              />
              {isSearchFilled && (
                <button
                  className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                  onClick={handleSearchClear}
                >
                  X
                </button>
              )}
            </Search>
          </div>
        </div>
        <div className="flex justify-between mt-auto">
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="manage collaboration tabs">
            <StyledTab value="ALLTYPE" label="All Types" />
            <StyledTab value="GIVETHANKS" label="Give Thanks" />
            <StyledTab value="INDIVIDUAL" label="Individual" />
            <StyledTab value="BUSINESS" label="Business" />
            <StyledTab value="NGO" label="NGO" />
          </StyledTabs>
        </div>

      </Paper >
      <UserManagementTable filterType={{ value: selectedTab, label: selectedLabel }} searchText={searchQuery} />
    </div>
  );
}