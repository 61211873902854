import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const addNewProduct = (data) => {
    return wrappedFetch(API.ADD_PRODUCT, "POST", data);
}
export const editProduct = (data) => {
    return wrappedFetch(API.EDIT_PRODUCT, "PUT", data);
}
export const deleteProduct = (data) => {
    return wrappedFetch(API.DELETE_PRODUCT, "DELETE", data);
}
export const fetchProduct = (pageNo, limit) => {
    return wrappedFetch(`${API.GET_PRODUCT}?pageNo=${pageNo}&limit=${limit}`, "GET");
}

