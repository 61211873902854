import React from "react";
import PropTypes from "prop-types";
const ViewPost = ({
  postData,
  handleDelete = () => {},
  handleEdit = () => {},
}) => {
  const handleDeleteModal = () => {
    handleDelete(true);
  };
  const handleEditModal = () => {
    handleEdit(true);
  };
  return (
    <div className=" flex flex-col gap-5 font-semibold justify-between">
      <div className="h-[452px] w-full bg-indigo-500 bg-opacity-20 overflow-hidden rounded-xl border-2  border-[#524CBB] ">
        {postData.media[0].mimeType?.startsWith("image/") ? (
          <img
            src={postData.media[0].imgUrl}
            alt=""
            className="w-full h-full object-contain"
          />
        ) : (
          <video controls className="w-full h-full">
            <source src={postData.media[0].imgUrl} type="video/mp4" />
            <track kind="captions" src="#" label="English" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="h-fit flex flex-row justify-center gap-4 mb-[10px]">
        <button
          className="text-[#888888] !bg-[#EEEEEE] text-[14px] font-bold rounded-[6px] px-[16px] py-[10px]"
          type="button"
          onClick={handleDeleteModal}>
          Delete
        </button>
        {postData?.sponsoredUserDetails?.userName && (
          <button
            className="bg-[#524CBB] text-[white] text-[14px] font-bold px-[16px] py-[10px] rounded-[6px]"
            type="button"
            onClick={handleEditModal}>
            Edit Post
          </button>
        )}
      </div>
    </div>
  );
};
ViewPost.propTypes = {
  postData: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
};
export default ViewPost;
