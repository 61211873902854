import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";


export const listUserData = (limit, pageNo, isWorkPlace, userType = "", businessType = "", startDate = "", endDate = "") => {
    return wrappedFetch(`${API.USER_DATA}?&limit=${limit}&pageNo=${pageNo}&userType=${userType}&isWorkPlace=${isWorkPlace}&businessType=${businessType}&startDate=${startDate}&endDate=${endDate}`, "GET");
}
export const dailyUserData = (limit, pageNo) => {
    return wrappedFetch(`${API.DAILY_USER_DATA}?&limit=${limit}&pageNo=${pageNo}`, "GET");
}
export const searchUser = (fullName) => {
    return wrappedFetch(`${API.SEARCH_USER}?userName=${fullName}`, "GET");
};
export const getGratitudeOfUser = (id) => {
    return wrappedFetch(`${API.GET_GRATITUDE}?_id=${id}`, "GET");
};