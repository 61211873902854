import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import PropTypes from 'prop-types';
import 'quill/dist/quill.snow.css';

const QuillEditor = ({ value, onChange, placeholder }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ]
        }
      });

      quillRef.current.on('text-change', () => {
        onChange(quillRef.current.root.innerHTML);
        togglePlaceholder();
      });

      quillRef.current.root.innerHTML = value;

      if (placeholder) {
        quillRef.current.root.dataset.placeholder = placeholder;
        togglePlaceholder();
      }
    }
  }, [value, onChange, placeholder]);

  const togglePlaceholder = () => {
    if (quillRef.current?.root?.dataset.placeholder) {
        const isEmpty = quillRef.current.getLength() === 1 && !quillRef.current.getText().trim();
        quillRef.current.root.dataset.placeholderVisible = isEmpty ? 'true' : 'false';
      }      
  };

  return (
    <div className="quill-editor-container">
      <div ref={editorRef} className="quill-editor" style={{
      border: '1px solid #C7C6C6',
      borderTop: "none",
      fontFamily: 'inherit',
      fontSize: '1rem',
      fontWeight: '300',
      borderBottomLeftRadius: '0.375rem',
      borderBottomRightRadius: '0.375rem',
      minHeight: '100px',
      position: 'relative' 
    }}/>
    </div>
  );
};

QuillEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default QuillEditor;
