import React, { useState } from 'react';
import ProfileDets from './profile_details/profile_details';
import GenericModal from '../../GenericModal';
import LogoutPage from './logoutPage';

const ProfilePopUp = () => {
    const [isProfileDetsOpen, setIsProfileDetsOpen] = useState(false);
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleClick = () => {
        handleOpenModal();
        setIsProfileDetsOpen(!isProfileDetsOpen);
        setIsLogoutOpen(false);
    };

    const handleLogout = () => {
        handleOpenModal();
        setIsLogoutOpen(!isLogoutOpen);
        setIsProfileDetsOpen(false);
    };

    return (
        <div className="w-[233px] h-fit p-5 bg-white rounded-[5px] flex flex-col gap-2.5">
            <div className="flex-col justify-start items-start gap-3 flex">
                <div className="text-neutral-800 text-sm font-medium font-['Inter']">
                    <button onClick={handleClick}>My Account</button>
                </div>
                <div className="text-neutral-800 text-sm font-medium font-['Inter']">
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>

            {isProfileDetsOpen && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={<ProfileDets />}
                    isCross={true}
                    title="Profile"
                    width="738px"
                    height="auto"
                />
            )}
            {isLogoutOpen && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={<LogoutPage setIsModalOpen={setIsModalOpen} />}
                />
            )}
        </div>
    );
};

export default ProfilePopUp;
