import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const profileDetails = () => {
  return wrappedFetch(API.PROFILE_DETAILS, "GET");
};

export const updatePassword = (data) => {
  return wrappedFetch(API.UPDATE_PASSWORD, "PUT", data);
};
export const editProfileDetails = (data) => {
  return wrappedFetch(API.EDIT_PROFILE, "POST", data);
};
