import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { getGratitudeOfUser } from '../../../API/userManagementApi';
import { toast } from 'react-toastify';



const UserView = ({ userData }) => {
    const [gratData, setGratData] = useState({});
    const renderMembers = (data) => {
        return (
            <div className="flex flex-wrap gap-3">
                {data.map((row) => (<div
                    className=" w-fit py-[5px] px-[10px] text-[#888888] bg-[#EEEEEE] text-[14px] font-bold rounded-[6px]"
                    key={row?._id}
                >
                    {row?.userName}
                </div>))}
            </div>
        );
    };
    useEffect(() => {
        const fetchUserData = () => {

            getGratitudeOfUser(userData?._id)
                .then((response) => setGratData(response?.data?.data))
                .catch((error) => toast.error(error?.message, {
                    autoClose: 5000, toastId: "fgdg"
                }));

        };

        fetchUserData();
    }, [userData]);
    return (
        <div className="card h-fit flex flex-col gap-4 font-semibold justify-between">
            <div className="h-fit flex flex-row justify-between gap-8">
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                        {userData?.isWorkPlace ? "Business Name" : "User Name"}</p>
                    <p className="break-words  text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                        {
                            userData?.isWorkPlace
                                ? (userData?.workPlace ?? "N/A")
                                : (userData?.userName ?? "N/A")
                        }</p>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Date of registration </p>
                    <p className="text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.createdAt ? (<Moment format="DD MMM, YYYY" >{userData?.createdAt}</Moment>) : "N/A"}</p>
                </div>
            </div>
            <div className="h-fit flex flex-row justify-between gap-8">
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Email Address</p>
                    <p className="break-words  text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.email ? userData?.email : "N/A"}</p>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Contact Number</p>
                    <p className=" text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.mobileNumber ? userData?.mobileNumber : "N/A"}</p>
                </div>
            </div>
            <div className="h-fit flex flex-row justify-between gap-8">
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Total Emoticons Received</p>
                    <p className=" text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{gratData?.totalGratitudeSent ? gratData?.totalGratitudeSent : "N/A"}</p>
                </div>

                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Total Tips Received</p>
                    <p className=" text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{gratData?.tipAmountSent ? `$${gratData?.tipAmountSent}` : "N/A"}</p>
                </div>
            </div>
            {(userData?.isWorkPlace) && <div className="h-fit flex flex-row justify-between gap-8">
                <div className="w-full flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>List of members linked to the profile</p>
                    <p className=" text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}> {userData?.workPlaceMembersDetails.length > 0 ? renderMembers(userData?.workPlaceMembersDetails) : "N/A"}</p>
                </div>
            </div>}
            {(!userData?.isWorkPlace && userData?.businessType === null) && <div className="h-fit flex flex-row justify-between gap-8">
                <div className="w-1/2 flex flex-col gap-2">
                    <p className=" text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Member of which Business</p>
                    <p className=" text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.workPlace || "N/A"}</p>
                </div>
            </div>}
        </div>

    );
}

UserView.propTypes = {
    userData: PropTypes.object,
}

export default UserView;
