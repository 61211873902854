import React from "react";
import Trash from "../../../../../Assets/images/icons/trash_white.png";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { deleteCollabPost } from "../../../../API/manageCollabApi";

const DeletePost = ({ setIsModalOpen, postId, isDeleted }) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDeletePost = () => {
    deleteCollabPost({ postId })
      .then((response) => {
        toast.success("Post Deleted Successfully", {
          autoClose: 5000,
          toastId: "Post deleted successfully",
        });
        setIsModalOpen(false);
        isDeleted(true);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error while deleting post",
        })
      );
  };
  return (
    <div className="h-auto flex flex-col gap-5 font-semibold">
      <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">
        <div className="gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#D33939]">
          <img
            src={Trash}
            alt=""
            className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]"
          />
        </div>
        <p className="font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] ">
          Are you sure you want to Delete this Post?
        </p>
        <p className="font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px]">
          After this post is deleted, you won't be able to view this post. Are
          you sure you want to delete this post?
        </p>
        <div className="flex gap-4 w-[162px] h-11  m-auto">
          <button
            className="w-[87px] bg-[#EEEEEE] rounded-[6px] "
            onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="w-[59px] bg-[#524CBB] rounded-[6px] text-white"
            onClick={handleDeletePost}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
DeletePost.propTypes = {
  setIsModalOpen: PropTypes.func,
  postId: PropTypes.string,
  isDeleted: PropTypes.func,
};
export default DeletePost;
