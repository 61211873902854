import React, { useEffect } from "react";
import Sidenav from "../screens/sidenav/sidenav";
import Navbar from "../screens/navbar/navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../screens/Dashboard/dashboard/dashboard";
import UserManagement from "../screens/Dashboard/user_management/user_management";
import FinanceManagement from "../screens/Dashboard/finance_management/finance";
import ReviewManagement from "../screens/Dashboard/review_management/review";
import Support from "../screens/Dashboard/grati_support/support";
import ManageAdmins from "../screens/Dashboard/manage_admins/manage_admins";
import { useDispatch, useSelector } from "react-redux";
import "../screens/Dashboard/manage_admins/manageEdit.css";
import Notifications from "../screens/navbar/notifications/notifications";
import { profileDetails } from "../API/myProfileApi";
import { userActions } from "../redux/reducers/user";
import ManageCollaboration from "../screens/Dashboard/manage_collaboration/manage_collaboration";
import OrderManagement from "../screens/Dashboard/order_management/order_management";
import ProductManagement from "../screens/Dashboard/product_management/product_management";

const AuthenticatedRoutes = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.user.permissions)[0];
  const role = useSelector((state) => state.user.role);
  useEffect(() => {
    profileDetails().then((response) =>
      dispatch(userActions.storeUser(response.data.data[0]))
    );
  }, []);

  return (
    <div className="flex flex-row">
      <Sidenav />

      <div className="card flex flex-col w-full">
        <Navbar />
        <div className="p-8 bg-gray-100 h-full">
          <Routes>
            {(role === 0 || permissions?.DASHBOARD) && (
              <Route path="dashboard" element={<Dashboard />} />
            )}

            {(role === 0 || permissions?.USER_MANAGEMENT) && (
              <Route path="user_management" element={<UserManagement />} />
            )}
            {(role === 0 || permissions?.FINANCE) && (
              <Route
                path="finance_management"
                element={<FinanceManagement />}
              />
            )}
            {(role === 0 || permissions?.REVIEW_AND_FEEDBACK) && (
              <Route path="review_management" element={<ReviewManagement />} />
            )}
            {role === 0 && <Route path="grati_support" element={<Support />} />}
            {(role === 0 || permissions?.SUB_ADMIN_MANAGEMENT) && (
              <Route path="manage" element={<ManageAdmins />} />
            )}

            <Route path="order_management" element={<OrderManagement />} />
            <Route path="product_management" element={<ProductManagement />} />

            {role === 0 && (
              <Route
                path="manage_collaboration"
                element={<ManageCollaboration />}
              />
            )}
            <Route path="notifications" element={<Notifications />} />
            <Route path="*" element={<Navigate replace to="dashboard" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedRoutes;
