import { createSlice } from "@reduxjs/toolkit";
import API from "../../API/urlConstants";

const token = localStorage.getItem(API.AUTH_TOKEN);

const initialState = {
    loggedIn: !!token,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action) {
            state.loggedIn = true;
            return state;
        },

        logout(state) {
            state.loggedIn = false;

            return state;
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;