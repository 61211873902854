import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Joi from "joi";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";

const CustomDatePicker = ({
  setDateRange,
  top,
  right,
  getDateRange,
  onClose,
}) => {
  const [startDate, setStartDate] = useState(
    setDateRange?.startDate ? setDateRange.startDate : new Date()
  );
  const [endDate, setEndDate] = useState(
    setDateRange?.endDate ? setDateRange.endDate : new Date()
  );
  const dateRangeValidation = Joi.object({
    fromDate: Joi.date()
      .required()
      .label("From Date")
      .error(new Error("Date is required")),
    toDate: Joi.date()
      .min(Joi.ref("fromDate"))
      .max(new Date())
      .error((errors) => {
        const [error] = errors;
        switch (error.code) {
          case "date.min":
            return new Error("Please select a valid date range");
          case "date.max":
            return new Error("Please select a valid date range");
          default:
            return new Error("Date is Required");
        }
      }),
  });

  const handleDateRange = () => {
    const startDateObj = new Date(startDate);
    startDateObj.setHours(0, 0, 0, 0);
    const fromDate = startDateObj.toISOString();
    const today = new Date();
    const isEndDateToday = dayjs(today).isSame(dayjs(endDate), "day");
    const endDateObj = new Date(endDate);
    if (!isEndDateToday) {
      endDateObj.setHours(23, 59, 59, 999);
    }
    const toDate = endDateObj.toISOString();

    const validData = dateRangeValidation.validate({
      fromDate,
      toDate,
    });
    if (validData?.error?.message) {
      toast.error(validData?.error?.message, {
        autoClose: 5000,
        toastId: "3435",
      });
      return;
    }
    getDateRange({ startDate: fromDate, endDate: toDate });
    onClose();
  };

  return (
    <Modal open={true} onClose={onClose}>
      <div
        style={{
          zIndex: 99,
          width: "370px",
          background: "white",
          position: "absolute",
          filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))",
          borderRadius: "4px",
          right: right || "100px",
          top: top || "0px",
          marginTop: "50px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}>
        <div className="w-full">
          <p className="w-max text-[14px] font-[600] text-[#888888]">From:</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100% !important",
                ".MuiOutlinedInput-input": {
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                },
              }}
              onChange={(e) => setStartDate(e)}
              disableFuture
              format="DD/MM/YYYY"
              value={dayjs(startDate)}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full">
          <p className="w-max text-[14px] font-[600] text-[#888888]">To:</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                ".MuiOutlinedInput-input": {
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                },
              }}
              onChange={(e) => setEndDate(e)}
              disableFuture
              format="DD/MM/YYYY"
              value={dayjs(endDate)}
            />
          </LocalizationProvider>
        </div>
        <div>
          <button
            type="button"
            className="flex flex-row justify-between w-fit items-center gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
            onClick={handleDateRange}>
            <p className="font-bold text-white">Apply</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

CustomDatePicker.propTypes = {
  getDateRange: PropTypes.func,
  setDateRange: PropTypes.object,
  onClose: PropTypes.func,
  right: PropTypes.string,
  top: PropTypes.string,
};

export default CustomDatePicker;
