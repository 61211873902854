import React, { useState } from "react";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import { addNewProduct, editProduct } from "../../../API/productApi";
import { toast } from "react-toastify";
import Joi from "joi";
import PropTypes from "prop-types";
import { Add, Remove } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import WestIcon from "@mui/icons-material/West";
import { v4 as uuidv4 } from "uuid";
import QuillEditor from "../../Editor/QuillEditor";

const validationData = Joi.object({
  productName: Joi.string().required().min(4).label("Product Name"),
  bleedSizeInches: Joi.string()
    .required()
    .label("Bleed Size")
    .error(new Error("Please enter bleed size in inches")),
  bleedSizeMm: Joi.string()
    .required()
    .label("Bleed Size")
    .error(new Error("Please enter bleed size in mm")),
  trimSizeInches: Joi.string()
    .required()
    .label("Trim Size")
    .error(new Error("Please enter trim size in inches")),
  trimSizeMm: Joi.string()
    .required()
    .label("Trim Size")
    .error(new Error("Please enter trim size in mm")),
  priceList: Joi.array()
    .items(
      Joi.object({
        id: Joi.number().required(),
        quantity: Joi.number().optional().allow("").messages({
          "number.base": "Quantity must be a number",
          "number.empty": "Quantity is required when cost is present",
        }),
        cost: Joi.number().optional().allow("").messages({
          "number.base": "Cost must be a number",
          "number.empty": "Cost is required when quantity is present",
        }),
      }).custom((value, helpers) => {
        const { quantity, cost } = value;
        if ((quantity && !cost) || (!quantity && cost)) {
          return helpers.message("Both quantity and cost must be provided");
        }
        return value;
      }, "Custom validation for quantity and cost dependency")
    )
    .min(1)
    .messages({
      "array.min": "At least one item must have both quantity and cost",
    })
    .custom((priceList, helpers) => {
      const validItems = priceList.filter((item) => item.quantity && item.cost);
      if (validItems.length === 0) {
        return helpers.message(
          "At least one valid item with price and quantity is required"
        );
      }
      return priceList;
    }, "Custom validation for priceList"),
  description: Joi.string()
    .required()
    .label("Product Description")
    .error(new Error("Please Enter Description")),
  post: Joi.array()
    .required()
    .min(1)
    .label("Photo")
    .error(new Error("Please Select a Photo to Upload")),
});

const AddProduct = ({ setIsAddOpen, productAdded, productData }) => {
  const [post, setPost] = useState(
    productData?.productImage?.length
      ? productData?.productImage.map((item) => ({
          imageUrl: item?.imageUrl,
          mimeType: item?.mimeType,
        }))
      : []
  );
  const [images, setImages] = useState(
    productData?.productImage?.length
      ? productData?.productImage.map((item) => ({
          url: item?.imageUrl,
          type: item?.mimeType,
        }))
      : []
  );
  const [errors, setErrors] = useState("");
  const [saved, setSaved] = useState(false);
  const [productName, setProductName] = useState(
    productData ? productData?.productName : ""
  );
  const [bleedSizeMm, setBleedSizeMm] = useState(
    productData?.size?.fullBleedSize ? productData?.size?.fullBleedSize[1] : ""
  );
  const [bleedSizeInches, setBleedSizeInches] = useState(
    productData?.size?.fullBleedSize ? productData?.size?.fullBleedSize[0] : ""
  );
  const [trimSizeMm, setTrimSizeMm] = useState(
    productData?.size?.trimmedSize ? productData?.size?.trimmedSize[1] : ""
  );
  const [trimSizeInches, setTrimSizeInches] = useState(
    productData?.size?.trimmedSize ? productData?.size?.trimmedSize[0] : ""
  );
  const [paperWeight, setPaperWeight] = useState(
    productData?.productDetails?.paperWeight
      ? productData?.productDetails?.paperWeight
      : ""
  );
  const [paperStock, setPaperStock] = useState(
    productData?.productDetails?.paperStock
      ? productData?.productDetails?.paperStock
      : ""
  );
  const [description, setDescription] = useState(
    productData ? productData?.productDescription : ""
  );
  const [priceList, setPriceList] = useState(
    productData?.quantity_price_object?.length
      ? productData?.quantity_price_object.map((item, index) => ({
          id: index,
          quantity: item.quantity,
          cost: item.price,
        }))
      : [{ id: 0, quantity: "", cost: "" }]
  );
  const [idCounter, setIdCounter] = useState(
    productData?.quantity_price_object?.length
      ? productData?.quantity_price_object?.length
      : 1
  );
  const imageId = uuidv4();
  
    const handleQuantityCostChange = (index, field, value) => {
      const newPriceList = [...priceList];
      newPriceList[index][field] = value;
      setPriceList(newPriceList);
    };
  
    const handleQuantityChange = (index, value) => {
      const inputValue = value.replace(/^\+/, "");
      const intValue = parseInt(inputValue, 10);
      if (
        inputValue === "" ||
        (!isNaN(intValue) && intValue >= 0 && intValue === parseFloat(inputValue))
      ) {
        handleQuantityCostChange(index, "quantity", inputValue);
      } else {
        const newPriceList = [...priceList];
        newPriceList[index].quantity = "";
        setPriceList(newPriceList);
        toast.error("Please enter a valid non-negative integer for quantity.", {
          autoClose: 5000,
          toastId: "Please enter valid quantity",
        });
      }
    };
  
    const handleCostChange = (index, value) => {
      const inputValue = value.replace(/^\+/, "");
      const floatValue = parseFloat(inputValue);
      if (inputValue === "" || (!isNaN(floatValue) && floatValue > 0)) {
        handleQuantityCostChange(index, "cost", inputValue);
      } else {
        toast.error("Please enter valid cost.", {
          autoClose: 5000,
          toastId: "Please enter valid cost",
        });
      }
    };
  
   
  const handleAddInput = () => {
    const lastItem = priceList[priceList.length - 1];
    if (lastItem.quantity !== "" && lastItem.cost !== "") {
      setPriceList([...priceList, { id: idCounter, quantity: "", cost: "" }]);
      setIdCounter(idCounter + 1);
    } else {
      toast.warning(
        "Please enter quantity and cost for product in this row to add new one.",
        {
          autoClose: 5000,
          toastId: "enter quantity and cost",
        }
      );
    }
  };

  const handleDeleteInput = (index) => {
    if (priceList.length === 1) return;

    if (
      (index === priceList.length - 1 &&
        priceList[index].quantity === "" &&
        priceList[index].cost === "") ||
      index !== priceList.length - 1
    ) {
      const newPriceList = priceList.filter((_, i) => i !== index);
      setPriceList(newPriceList);
      toast.success(`Quantity ${index + 1} deleted successfully`, {
        autoClose: 5000,
        toastId: `Quantity ${index + 1} deleted successfully`,
      });
    }
  };
  const handleCancelImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    const newPost = post.filter((_, i) => i !== index);
    setPost(newPost);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (!selectedFiles.length) return;
    setErrors("");
    const validFiles = selectedFiles.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
    );
    if (validFiles.length !== selectedFiles.length) {
      setErrors(
        "Unsupported file format. Only JPEG, JPG, PNG images are allowed."
      );
      return;
    }
    handleImageFiles(validFiles);
  };

  const handleImageFiles = (files) => {
    const minSizePhoto = 25 * 1024;
    const maxSizePhoto = 5 * 1024 * 1024;
    const validSizeFiles = files.filter(
      (file) => file.size >= minSizePhoto && file.size <= maxSizePhoto
    );
  
    if (validSizeFiles.length !== files.length) {
      setErrors("Photo size must be between 25 Kilobytes to 5 Mb.");
      return;
    }
  
    if (images.length + validSizeFiles.length > 5) {
      setErrors("Maximum 5 images allowed.");
      return;
    }
  
    validSizeFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = handleImageLoad(file);
      };
      reader.readAsDataURL(file);
    });
  };
  
  const handleImageLoad = (file) => () => {
    setImages((prevImages) => [
      ...prevImages,
      { url: URL.createObjectURL(file), type: file.type },
    ]);
    setPost((prevPost) => [...prevPost, file]);
  };
  const handleBleedInchesChange = (e) => {
    setBleedSizeInches(e?.target?.value);
  };
  const handleBleedMmChange = (e) => {
    setBleedSizeMm(e?.target?.value);
  };
  const handleTrimInchesChange = (e) => {
    setTrimSizeInches(e?.target?.value);
  };
  const handleTrimMmChange = (e) => {
    setTrimSizeMm(e?.target?.value);
  };
  const handleNameChange = (e) => {
    setProductName(e?.target?.value);
  };
 
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const handlePaperStockChange = (e) => {
    setPaperStock(e?.target?.value);
  };
  const handlePaperWeightChange = (e) => {
    setPaperWeight(e?.target?.value);
  };
  const handleCancel = () => {
    setIsAddOpen(false);
  };
  const handleSaveProduct = (e) => {
    const validation = validationData.validate({
      productName,
      bleedSizeInches,
      bleedSizeMm,
      trimSizeInches,
      trimSizeMm,
      priceList,
      description,
      post,
    });

    if (validation.error) {
      toast.error(validation.error.message, {
        autoClose: 5000,
        toastId: "Validation Error",
      });
      return;
    }
    let data = new FormData();
   
      post?.forEach((item) => {
        data.append("media", item);
      });
    for (let i = 0; i < priceList.length; i++) {
      const item = priceList[i];
      if (item.cost === "") {
        break;
      }
      data.append(`quantity_price_object[${i}][quantity]`, item.quantity);
      data.append(`quantity_price_object[${i}][price]`, item.cost);
    }
    data.append("fullBleedSize", bleedSizeInches);
    data.append("fullBleedSize", bleedSizeMm);
    data.append("trimmedSize", trimSizeInches);
    data.append("trimmedSize", trimSizeMm);
    if (paperWeight) {
      data.append("productDetails[paperWeight]", paperWeight);
    }
    
    if (paperStock) {
      data.append("productDetails[paperStock]", paperStock);
    }
    
    data.append("productName", productName);
    
    if (description) {
      data.append("productDescription", description);
    }
    setSaved(true);
    addNewProduct(data)
      .then((response) => {
        toast.success("Product Added Successfully", {
          autoClose: 5000,
          toastId: "Product added successfully",
        });
        productAdded(true);
        handleCancel();
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error adding new Product",
        })
      )
      .finally(() => {
        setSaved(false);
      });
  };
  const handleEditProduct = () => {
    const validation = validationData.validate({
      productName,
      bleedSizeInches,
      bleedSizeMm,
      trimSizeInches,
      trimSizeMm,
      priceList,
      description,
      post,
    });

    if (validation.error) {
      toast.error(validation.error.message, {
        autoClose: 5000,
        toastId: "Validation Error",
      });
      return;
    }
    let data = new FormData();
    const mediaItems = [];

    post?.forEach((item) => {
      if (item?.imageUrl) {
        mediaItems.push(item);
      } else {
        data.append("media", item);
      }
    });

    if (mediaItems.length > 0) {
      data.append("media", JSON.stringify(mediaItems));
    }
    for (let i = 0; i < priceList.length; i++) {
      const item = priceList[i];
      if (item.cost === "") {
        break;
      }
      data.append(`quantity_price_object[${i}][quantity]`, item.quantity);
      data.append(`quantity_price_object[${i}][price]`, item.cost);
    }
    data.append("fullBleedSize", bleedSizeInches);
    data.append("fullBleedSize", bleedSizeMm);
    data.append("trimmedSize", trimSizeInches);
    data.append("trimmedSize", trimSizeMm);
    if (paperWeight) {
      data.append("productDetails[paperWeight]", paperWeight);
    }
    
    if (paperStock) {
      data.append("productDetails[paperStock]", paperStock);
    }
    
    data.append("productName", productName);
    
    if (description) {
      data.append("productDescription", description);
    }
    data.append("_id", productData?._id);
    setSaved(true);
    editProduct(data)
      .then((response) => {
        toast.success("Product Edited Successfully", {
          autoClose: 5000,
          toastId: "Product Edited Successfully",
        });
        productAdded(true);
        handleCancel();
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error editing  Product",
        })
      )
      .finally(() => {
        setSaved(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (productData) {
      handleEditProduct();
    } else {
      handleSaveProduct();
    }
  };
  return (
    <>
      <div className="flex gap-7 flex-col" id={productData?._id}>
        <div className="h-fit flex flex-row justify-start items-center mb-[10px] gap-4">
          <button type="button" onClick={handleCancel}>
            <WestIcon style={{ color: "#888888", opacity: "0.5" }} />
          </button>
          <p
            className="text-[18px] font-[700] text-black"
            style={{ fontFamily: "Plus Jakarta Sans" }}>
            {productData ? "Edit product" : "Add Product"}
          </p>
        </div>
      </div>

      <div className="card  flex flex-col gap-5 font-semibold justify-between pb-4">
        <div className="h-fit w-full flex flex-row justify-between gap-8">
          <div className=" flex w-full flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Product Name *
            </p>
            <input
              type={"text"}
              name="productName"
              value={productName}
              onChange={(e) => handleNameChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Enter Product Name"
            />
          </div>
        </div>
        <div className="h-fit flex flex-row justify-between gap-8">
          <div className="w-1/2 flex flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Full Bleed Size *
            </p>
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              In Inches:
            </p>
            <input
              type={"text"}
              value={bleedSizeInches}
              onChange={(e) => handleBleedInchesChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Enter dimensions in inches"
            />
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>In mm:</p>
            <input
              type={"text"}
              value={bleedSizeMm}
              onChange={(e) => handleBleedMmChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Enter dimensions in mm"
            />
          </div>
          <div className="w-1/2 flex flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Trim Size *
            </p>
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              In Inches:
            </p>
            <input
              type={"text"}
              value={trimSizeInches}
              onChange={(e) => handleTrimInchesChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Enter dimensions in inches"
            />
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>In mm:</p>
            <input
              type={"text"}
              value={trimSizeMm}
              onChange={(e) => handleTrimMmChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Enter dimensions in mm"
            />
          </div>
        </div>
        <div className="h-fit w-full flex flex-row justify-between gap-8">
          <div className="w-1/2 flex">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Quantity *
            </p>
          </div>

          <div className="w-1/2 flex">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Grati Represented Cost (Shipping not Included) *
            </p>
          </div>
        </div>
        {priceList.map((item, index) => (
          <div
            key={item.id}
            className="h-fit w-full flex flex-row justify-between gap-8 items-center">
            <div className="w-1/2 flex">
              <input
                type={"number"}
                min={0}
                step={1}
                value={item.quantity}
                onChange={(e) => handleQuantityChange(index, e.target.value)}
                className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                placeholder={`Quantity ${index + 1}`}
              />
            </div>

            <div className="w-1/2 flex items-center gap-2 justify-between">
              <input
                type={"number"}
                onChange={(e) => handleCostChange(index, e.target.value)}
                value={item.cost}
                className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
                style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                placeholder={`Grati Represented Cost for item ${index + 1}`}
              />

              {index === priceList.length - 1 ? (
                <Add sx={{ color: "#a1a0a3" }} onClick={handleAddInput} />
              ) : (
                <Remove
                  sx={{ color: "#a1a0a3" }}
                  onClick={() => handleDeleteInput(index)}
                />
              )}
            </div>
          </div>
        ))}
        <div className="h-fit flex flex-row justify-between gap-8">
          <div className="w-full flex flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Paper Weight
            </p>
            <input
              type={"text"}
              name="paperWeight"
              value={paperWeight}
              onChange={(e) => handlePaperWeightChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Add Paper Weight"
            />
          </div>
        </div>
        <div className="h-fit flex flex-row justify-between gap-8">
          <div className="w-full flex flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Paper Stock
            </p>
            <input
              type={"text"}
              name="paperStock"
              value={paperStock}
              onChange={(e) => handlePaperStockChange(e)}
              className="w-full border-[1px] border-[#C7C6C6] font-light text-base rounded-md p-[10px]"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              placeholder="Add Paper Stock"
            />
          </div>
        </div>
        <div className="h-fit flex flex-row justify-between gap-8">
          <div className="w-full flex flex-col gap-2">
            <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
              Description *
            </p>
            <div className="mb-4">
        <QuillEditor
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Add Description . . . "
        />
      </div>
          </div>
        </div>
        <div className="h-fit flex flex-col justify-start gap-4">
          <div className="h-fit flex flex-row justify-start items-end gap-8">
            <div className="w-1/4 flex flex-col gap-2">
              <p style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>
                Media *
              </p>
              <button
                className="w-full h-fit bg-indigo-500 bg-opacity-20 rounded-xl border-2 border-[#524CBB] flex flex-row justify-center items-center relative  p-[16px]"
                type="button"
                onClick={() => {
                  document.getElementById(`add-post-${imageId}`).click();
                }}>
                <div>
                  <AddLinkOutlinedIcon className="!text-3x text-[#524CBB]" />
                  <p className="text-[#524CBB]">Choose Photo to upload</p>
                </div>
                <input
                  type="file"
                  accept="image/png,  image/jpeg, image/jpg"
                  id={`add-post-${imageId}`}
                  key={`add-post-${imageId}`}
                  style={{ display: "none" }}
                  multiple
                  onChange={handleFileSelect}
                />
              </button>
            </div>
            <div className="flex flex-wrap gap-4">
              {images.map((image, index) => (
                <div
                  key={image.url}
                  className="w-[100px] h-[100px] rounded-xl relative">
                  <img
                    src={image.url}
                    alt=""
                    className="h-full w-full object-cover rounded-xl"
                  />
                  <CancelIcon
                    className="absolute top-[-10px] right-[-12px] text-[#979797]"
                    onClick={() => handleCancelImage(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          {errors && (
            <p
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
              className="text-[red] text-[14px] font-[500] ">
              {errors}
            </p>
          )}
        </div>
      </div>
      <div className="h-fit flex flex-row justify-end gap-4 mb-[10px]">
        <button
          className="text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px] px-[16px] py-[10px]"
          type="button"
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="bg-[#524CBB] text-[white] text-[14px] font-medium px-[16px] py-[10px] rounded-[6px]"
          type="submit"
          disabled={saved}
          style={saved ? { color: "#888888", background: "#EEEEEE" } : {}}
          onClick={handleSubmit}>
          {productData?.productName ? "Edit Product" : "Save Product"}
        </button>
      </div>
    </>
  );
};
AddProduct.propTypes = {
  setIsAddOpen: PropTypes.func,
  productData: PropTypes.object,
  productAdded: PropTypes.func,
};
export default AddProduct;
