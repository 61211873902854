import React, { useState } from "react";
import PropTypes from 'prop-types';
import Circle from "../../../../Assets/images/icons/tick-circle.png";
import { TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { toast } from "react-toastify";
import { reviewActions } from "../../../API/reviewManagementApi";
import Moment from "react-moment";
import "./viewReview.css";

const EditReview = ({ setIsModal, data, setIsOpenModal }) => {
    const CHARACTER_LIMIT = 250;
    const [comment, setComment] = useState(data?.sayThanks.split(/\s+/)
        .filter(Boolean)
        .join(' '));
    const handleClose = () => {
        setIsOpenModal(false);
    }
    const handleEdit = () => {
        if (data?.sayThanks.length !== 0 && comment === "") {
            toast.error("Review cannot be empty", {
                autoClose: 5000,
                toastId: "dsfw v"
            });
            return;
        }
        const postForm = {
            _id: data?._id,
            sayThanks: comment.split(/\s+/)
                .filter(Boolean)
                .join(' '),
            actionType: "edit"
        }
        reviewActions(postForm)
            .then((res) => {
                toast.success("Review Edited Successfully", {
                    autoClose: 5000,
                    toastId: "rgevx"
                });
                handleClose();
                setIsModal(false);
            })
            .catch((error) => toast.error(error?.message, {
                autoClose: 5000, toastId: "ef"
            }))
    }
    return (
        <div className="h-[401px] w-[583px] flex flex-col gap-6 font-semibold">
            <div className="h-[35px] flex justify-between">
                <p className="h-[24px] text-black text-[20px] font-bold self-center">
                    {data?.icon === 'RECOGNITION' && '🤝 Recognition'}
                    {data?.icon === 'CELEBRATION' && '🙌 Celebration'}
                    {data?.icon === 'GRATITUDE' && '🙏 Gratitude'}
                    {data?.icon === 'APPRECIATION' && '👍 Appreciation'}
                    {data?.icon === undefined && "N/A"}
                </p>
                <p className="w-fit h-5 text-black text-[14px] font-medium self-center">
                    <Moment format="DD MMM YYYY , hh:mm A" >
                        {data?.createdAt}
                    </Moment>
                </p>
            </div>

            <div className="w-[583px] h-[260px] flex flex-col gap-2">
                <p className="w-[56px] h-[24px] text-black text-[16px] font-semi-bold">
                    Review
                </p>
                <div className="w-[583px] h-[204px] rounded-1 bg-[#EEEEEE] p-[12px] flex flex-col gap-[10px]">
                    <TextField
                        inputProps={{
                            maxLength: CHARACTER_LIMIT,
                            style: {
                                maxHeight: "180px",
                                overflow: "auto",
                                height: "160px",
                                fontFamily: "Plus Jakarta Sans,sans-serif",
                                lineHeight: "20px",
                                fontWeight: "500",
                                fontStyle: "italic",
                                fontSize: "14px",
                                color: "#888888",
                            },
                        }}
                        multiline
                        defaultValue={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                        margin="normal"
                        className="w-full max-h-[180px] "
                        style={{ margin: "0px" }}
                    />
                </div>
                <div className="flex justify-end w-[583px] h-5 ">
                    <FormHelperText
                        className="h-[20px] w-fit"
                        style={{
                            maxHeight: "180px",
                            overflow: "auto",
                            height: "160px",
                            fontFamily: "Plus Jakarta Sans,sans-serif",
                            lineHeight: "20px",
                            fontWeight: "500",
                            fontStyle: "italic",
                            fontSize: "14px",
                            color: "#888888",
                        }}
                    >{`${comment?.length}/${CHARACTER_LIMIT}`}</FormHelperText>
                </div>

                <div className="w-[545px] h-[58px] flex gap-9">
                    <div className="w-[260px] h-[58px] flex flex-col gap-1">
                        <p className="w-fit h-6 text-black text-[20px] font-semi-bold">
                            Give Thanks
                        </p>
                        <div className="w-[125px] h-[30px] flex gap-1">
                            <img
                                src={data?.giveThanksDetails?.profilePicture || Circle}
                                alt=""
                                className="w-[30px] h-[30px] border-2 border-[#524CBB] rounded-full"
                            />
                            <p className="w-fit h-5 text-black text-[14px] font-medium self-center">
                                {data?.giveThanksDetails?.userName || <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
                            </p>
                        </div>
                    </div>

                    <div className="w-[260px] h-[58px] flex flex-col gap-1">
                        <p className="w-fit h-6 text-black text-[20px] font-semi-bold">
                            Get Thanks
                        </p>
                        <div className="w-[125px] h-[30px] flex gap-1">
                            <img
                                src={data?.getThanksDetails?.profilePicture || Circle}
                                alt=""
                                className="w-[30px] h-[30px] border-2 border-[#524CBB] rounded-full"
                            />
                            <p className="w-fit h-5 text-black text-[14px] font-medium self-center">
                                {data?.getThanksDetails?.userName || <p className='text-[14px] font-[600]' style={{ color: 'rgba(136, 136, 136, 0.5)', fontFamily: "Plus Jakarta Sans" }}>N/A</p>}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-[232px] h-[45px] flex gap-[12px] mt-[45px] m-auto">
                    <button className="w-[110px] rounded-[6px] py-[10px] px-4 bg-[#EEEEEE] text-[#888888] text-4 font-bold"
                        style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }} onClick={handleClose}>Cancel</button>
                    <button className="w-[110px] rounded-[6px] py-[10px] px-4 bg-[#524CBB] text-white text-4 font-bold"
                        style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }} onClick={handleEdit}>Save</button>
                </div>
            </div>
        </div>
    );
};

EditReview.propTypes = {
    setIsModal: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    setIsOpenModal: PropTypes.func.isRequired,
};

export default EditReview;
