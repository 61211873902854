import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../../../../Assets/images/icons/search.png";
import Eye from "../../../../Assets/images/icons/eye.png";
import Block from "../../../../Assets/images/icons/block.png";
import Unblock from "../../../../Assets/images/icons/unblock_grey.png";
import Trash from "../../../../Assets/images/icons/trash.png";
import Edit from "../../../../Assets/images/icons/edit-2.png";
import GenericModal from "../../../GenericModal";
import ManageEdit from "./manageEditCopy";
import ManageAdd from "./manageAdd";
import ManageView from "./manageView";
import DeleteAdmin from "./deleteAdmin";
import AdminBlock from "./blockAdminCopy";
import { listAdminDets, searchAdmin } from "../../../API/manageAdminApi";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import "./manage.css";
import "./manageEdit.css";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    zIndex: "1",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: "30px",
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

export default function ManageAdmins() {
    const [userId, setUserId] = useState("");
    const [isRole, setIsRole] = useState("");
    const [yes, setYes] = useState()
    const [tableData, setTableData] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isBlock, setIsBlock] = useState(false);
    const [isDel, setIsDel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchFilled, setIsSearchFilled] = useState(false);
    const role = useSelector((state) => state?.user?.role);

    const handlePaginationLimitChange = (event) => {
        setLimit(event?.target?.value);
        setPage(1);
    };
    const handleFilter = (event) => {
        setFilter(event?.target?.value);
        setPage(1);
    };

    const handlePageChange = (event, page) => {
        setPage(page);
    }

    const fetchUserData = () => {
        setLoading(true);
        listAdminDets(filter, (page), limit)
            .then((response) => {
                setSearchQuery("");
                setTableData(response?.data?.data?.users);
                setTotalCount(response?.data?.data?.totalCount);
            })
            .catch((error) => toast.error(error?.message, {
                autoClose: 5000,
                toastId: "dfgbz"
            }))
            .finally(() => setLoading(false));

    };

    useEffect(() => {
        fetchUserData();
    }, [filter, page, limit]);


    const handleSearch = (value) => {
        setPage(1);
        if (value !== "") {
            setLoading(true);
            searchAdmin(value)
                .then((response) => {
                    setTableData(response?.data?.data);
                    setTotalCount(0)
                })
                .catch((error) => toast.error("Error fetching search results:", {
                    autoClose: 5000, toastId: "gdg"
                }))
                .finally(() => setLoading(false));

        } else if (value === "") {
            fetchUserData();
        }

    };
    const handleChange = (event) => {
        event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
        setSearchQuery(event?.target?.value);
        handleSearch(event?.target?.value);
    };

    const handleSearchClear = (event) => {
        event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
        setSearchQuery("");
        handleSearch("");
    };

    let title = ["View Details", "Edit Details", "Add Admin / Sub-Admin"];

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsView(!isView);
        setIsEdit(!isEdit);
        setIsAdd(!isAdd);
        setIsDel(!isDel);
        setIsBlock(!isBlock);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsView(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsDel(false);
        setIsBlock(false);
    };

    const handleView = async (id) => {
        setIsBlock(false);
        setIsView(!isView);
        handleOpenModal();
        setIsEdit(false);
        setIsAdd(false);
        setIsDel(false);
        setIsBlock(false);
        setUserId(id);
    };
    const handleEdit = (id, role) => {
        handleOpenModal();
        setIsView(false);
        setIsAdd(false);
        setIsDel(false);
        setIsBlock(false);
        setIsEdit(!isEdit);
        setUserId(id);
        setIsRole(role)
    };

    const handleAdd = () => {
        handleOpenModal();
        setIsEdit(false);
        setIsView(false);
        setIsDel(false);
        setIsBlock(false);
        setIsAdd(!isAdd);
    };

    const handleDelete = (id, role) => {
        handleOpenModal();
        setIsEdit(false);
        setIsView(false);
        setIsAdd(false);
        setIsBlock(false);
        setIsDel(!isDel);
        setUserId(id);
        setIsRole(role)
    };

    const handleBlock = (id, role, yes) => {
        handleOpenModal();
        setIsBlock(!isBlock);
        setIsEdit(false);
        setIsView(false);
        setIsAdd(false);
        setIsDel(false);
        setUserId(id);
        setIsRole(role);
        setYes(yes)
    };

    const getRoleLabel = (role) => {
        switch (role) {
            case 1:
                return "Admin";
            case 2:
                return "Subadmin";
            default:
        }
    };

    useEffect(() => {
        if (isDeleted) {
            fetchUserData();
            setIsDeleted(false);
        }
        if (isBlocked) {
            fetchUserData();
            setIsBlocked(false)
        }
        if (isAdded) {
            fetchUserData();
            setIsAdded(false)
        }
        if (isEdited) {
            fetchUserData();
            setIsEdited(false)
        }
    }, [isDeleted, isBlocked, isAdded, isEdited]);

    const limitOptions = [10, 25, 50, 75, 100]

    return (
        <Paper
            className=" h-[100%] max-h-[87vh] flex flex-col justify-between gap-6 p-6"
            style={{ borderRadius: "12px", justifyContent: "flex-start" }}
            component={Paper}
        >
            <div className="flex justify-between" style={{ alignItems: "baseline" }}>
                <p
                    className="text-[18px] font-[700] text-black"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                >
                    Manage Admins
                </p>
                <div
                    className="flex h-11 gap-3 font-[600] text-[14px]"
                    style={{ fontFamily: "Plus Jakarta Sans" }}
                >
                    <Select
                        labelId="pagination-limit-select-label"
                        id="pagination-limit-select"
                        value={filter || ""}
                        displayEmpty
                        onChange={handleFilter}
                        className=" bg-[#EEEEEE] w-[145px] h-11 rounded-[6px] gap-1 py-2 px-[14px]"
                        style={{
                            fontFamily: "Inter",
                            lineHeight: "20px",
                            fontWeight: "500",
                            textSize: "14px",
                            padding: "0",
                            color: "#888888",
                        }}
                    >
                        <MenuItem
                            style={{
                                "&:hover": {
                                    backgroundColor: "#EEEEEE",
                                },
                            }}
                            value={""}
                        >
                            All
                        </MenuItem>
                        <MenuItem
                            style={{
                                "&:hover": {
                                    backgroundColor: "#EEEEEE",
                                },
                            }}
                            value={1}
                        >
                            Admin
                        </MenuItem>
                        <MenuItem
                            style={{
                                "&:hover": {
                                    backgroundColor: "#EEEEEE",
                                },
                            }}
                            value={2}
                        >
                            Sub-Admin
                        </MenuItem>
                    </Select>

                    <Search className="flex flex-row justify-between relative !mr-0">
                        <SearchIconWrapper>
                            <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search by User Name"
                            value={searchQuery}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "search" }}
                            className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
                        />
                        {isSearchFilled && (
                            <button
                                className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                                onClick={handleSearchClear}
                            >
                                X
                            </button>
                        )}
                    </Search>

                    {role !== 2 && <button
                        type="button"
                        className="flex flex-row justify-between items-center w-16 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
                        onClick={handleAdd}
                    >
                        <p
                            className="font-bold text-center text-white"
                            style={{
                                fontFamily: "Plus Jakarta Sans,sans-serif",
                                lineHeight: "24px",
                                fontWeight: "500",
                                textSize: "16px",
                                padding: "0",
                            }}
                        >
                            Add
                        </p>
                    </button>}
                </div>
            </div>
            {loading && (
                <div className="flex items-center justify-center mt-20">
                    <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
                </div>

            )}
            {!loading && <TableContainer className="card w-auto overflow-auto">
                {tableData?.length !== 0 ? <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.8 }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className=" h-11 p-3">
                                <TableCell className="w-[85px] h-11 p-3">
                                    <p
                                        className="text-[14px] font-[600] text-[#888888]"
                                        style={{ fontFamily: "Plus Jakarta Sans" }}
                                    >
                                        S. No.
                                    </p>
                                </TableCell>
                                <TableCell className="w-[233px] h-11 p-3">
                                    <p
                                        className="text-[14px] font-[600] text-[#888888]"
                                        style={{ fontFamily: "Plus Jakarta Sans" }}
                                    >
                                        User Name
                                    </p>
                                </TableCell>

                                <TableCell className="w-[213.5px] h-11 p-3">
                                    <p
                                        className="text-[14px] font-[600] text-[#888888]"
                                        style={{ fontFamily: "Plus Jakarta Sans" }}
                                    >
                                        Email Address
                                    </p>
                                </TableCell>

                                <TableCell className="w-[213.5px] h-11 p-3">
                                    <p
                                        className="text-[14px] font-[600] text-[#888888]"
                                        style={{ fontFamily: "Plus Jakarta Sans" }}
                                    >
                                        User Role
                                    </p>
                                </TableCell>

                                <TableCell className="w-[179px] h-11 p-3">
                                    <p
                                        className="text-[14px] font-[600] text-[#888888]"
                                        style={{ fontFamily: "Plus Jakarta Sans" }}
                                    >
                                        Action
                                    </p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, index) => (
                                <TableRow
                                    key={row?._id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    className="w-[179px] h-13"
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                            color: "#888888",
                                            fontFamily: "Plus Jakarta Sans",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        <p>{index + 1}</p>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                            fontFamily: "Plus Jakarta Sans",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                        }}
                                    >
                                        <p>{row?.fullName}</p>
                                    </TableCell>

                                    <TableCell
                                        style={{
                                            fontFamily: "Plus Jakarta Sans",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                        }}
                                    >
                                        <p>{row?.email}</p>
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        style={{
                                            fontFamily: "Plus Jakarta Sans",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                        }}
                                    >

                                        {getRoleLabel(row?.role)}
                                    </TableCell>

                                    <TableCell align="left">
                                        <div className="flex justify-left gap-[10px]">
                                            <button
                                                type="button"
                                                className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
                                                onClick={(e) => handleView(row?._id)}
                                            >
                                                <img src={Eye} alt="" className="w-5 h-5" />
                                            </button>
                                            {role !== 2 && <button
                                                type="button"
                                                className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
                                                onClick={(e) =>
                                                    handleEdit(row?._id, row?.role)
                                                }
                                            >
                                                <img src={Edit} alt="" className="w-5 h-5" />
                                            </button>}
                                            {role !== 2 && <button
                                                type="button"
                                                className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
                                                onClick={(e) => handleDelete(row?._id, row?.role)}
                                            >
                                                <img src={Trash} alt="" className="w-5 h-5" />
                                            </button>}
                                            {role !== 2 && <button
                                                type="button"
                                                className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
                                                onClick={(e) => handleBlock(row?._id, row?.role, row?.isBlocked)}
                                            >
                                                {row?.isBlocked ? <img src={Unblock} alt="" className="w-5 h-5" /> : <img src={Block} alt="" className="w-5 h-5" />}
                                            </button>}
                                        </div>
                                        {row?.action}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </motion.div> : <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">No data found</p>}
            </TableContainer >}          {isEdit && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={
                        <ManageEdit
                            setIsEdited={setIsEdited}
                            userId={userId}
                            setIsOpenModal={setIsModalOpen}
                            isRole={isRole}
                        />
                    }
                    isCross={true}
                    title={title[1]}
                    width="631px"
                    height="758px"
                />
            )}

            {isAdd && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={
                        <ManageAdd
                            setIsAdded={setIsAdded}
                            setIsOpenModal={setIsModalOpen}
                        />
                    }
                    isCross={true}
                    title={title[2]}
                    width="640px"
                    height="758px"
                />
            )}

            {isView && (
                <GenericModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    content={<ManageView userId={userId} />}
                    isCross={true}
                    title={title[0]}
                    width="631px"
                    height="fit-content"
                />
            )}

            {isDel && (
                <GenericModal
                    open={isModalOpen}
                    tableData={tableData}
                    setTableData={setTableData}
                    handleClose={handleCloseModal}
                    content={
                        <DeleteAdmin
                            setIsDeleted={setIsDeleted}
                            userId={userId}
                            setIsOpenModal={setIsModalOpen}
                            isRole={isRole}
                            userType={"admin"}
                        />
                    }
                    isCross={false}
                    width={"391px"}
                    height={"328px"}
                />
            )}

            {isBlock && (
                <GenericModal
                    open={isModalOpen}
                    tableData={tableData}
                    setTableData={setTableData}
                    handleClose={handleCloseModal}
                    content={
                        <AdminBlock
                            isBlocked={isBlocked}
                            setIsBlocked={setIsBlocked}
                            userId={userId}
                            isRole={isRole}
                            yes={yes}
                            setIsOpenModal={setIsModalOpen} // opening success modal

                        />
                    }
                    isCross={false}
                    width={"391px"}
                    height={"328px"}
                />
            )}

            {/* </div> */}
            <div className="flex justify-between mt-auto">
                <Select
                    labelId="pagination-limit-select-label"
                    value={limit}
                    onChange={handlePaginationLimitChange}
                    className="w-[66px] h-11 bg-[#EEEEEE] "
                    style={{
                        color: "#888888",
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "fit-content"
                    }}
                >
                    {limitOptions.map((options) => (<MenuItem
                        key={options}
                        style={{
                            "&:hover": {
                                backgroundColor: "#EEEEEE",
                            },
                        }}
                        value={options}
                    >
                        {options}
                    </MenuItem>))}
                </Select>

                <Stack spacing={2} >
                    <Pagination
                        count={Math.ceil(totalCount / limit)}
                        defaultPage={1}
                        siblingCount={3}
                        shape="rounded"
                        page={page}
                        onChange={handlePageChange}
                    />
                </Stack>
            </div>
        </Paper>
    );
}
