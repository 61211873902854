import React, { useEffect, useState } from 'react'
import { viewAdminDets } from '../../../API/manageAdminApi';
import "./manageEdit.css";
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const ManageView
    = ({ userId }) => {
        const [userData, setUserData] = useState();
        const [loading, setLoading] = useState(true);
        const fetchUserData = () => {
            setLoading(true);
            viewAdminDets(userId)
                .then((response) => setUserData(response?.data?.data[0]))
                .catch((error) => toast.error(error?.message, {
                    autoClose: 5000, toastId: "awewafr"
                }))
                .finally(() => setLoading(false))

        };

        useEffect(() => {
            fetchUserData();
        }, [userId]);
        const getRoleLabel = (role) => {
            switch (role) {
                case 1:
                    return 'Admin';
                case 2:
                    return 'Sub-Admin';
                default:
                    return 'Unknown Role';
            }
        };
        return (
            <div className='h-fit w-[545px] flex flex-col gap-6 font-semibold'>
                {loading ? <div className="flex flex-col justify-center items-center p-0"> <CircularProgress style={{ color: "#888888", opacity: "0.5", margin: "100px" }} /></div>
                    : (<><div className=" flex w-[545px] gap-9 h-12">
                        <div className="flex flex-col w-[260.5px] h-12  gap-1">
                            <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>User Name</p>
                            <p className="  h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.fullName}</p>
                        </div>
                        <div className="flex flex-col w-[260.5px] h-12  gap-1">
                            <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Email Address</p>
                            <p className="  h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.email}</p>
                        </div>
                    </div>

                        <div className="flex w-[260.5px] gap-1 h-12">
                            <div className="flex flex-col w-[260.5px] h-12  gap-1">
                                <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Role</p>
                                <p className=" w-fit h-5 text-[#888888] text-[14px] font-medium" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{getRoleLabel(userData?.role)}</p>
                            </div>
                        </div>

                        <div className="flex w-[545px] gap-6 h-fit">
                            <div className="flex w-[100%] flex-col h-fit gap-[11px]">
                                <p className=" w-fit h-6 text-black text-4 font-semibold" style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Range of Access</p>
                                <div className="w-max   h-fit  pr-3" >
                                    <ul className="flex flex-col gap-4 list-disc pl-6">
                                        {userData?.permissions[0]?.DASHBOARD?.limited &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Dashboard (Limited Access)</li>
                                        }
                                        {userData?.permissions[0]?.DASHBOARD?.fullAccess &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Dashboard (Full Access)</li>
                                        }
                                        {userData?.permissions[0]?.USER_MANAGEMENT?.limited &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>User Management (Limited Access)</li>
                                        }
                                        {userData?.permissions[0]?.USER_MANAGEMENT?.fullAccess &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>User Management (Full Access)</li>
                                        }
                                        {userData?.permissions[0]?.REVIEW_AND_FEEDBACK?.limited &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Review Management (Limited Access)</li>
                                        }
                                        {userData?.permissions[0]?.REVIEW_AND_FEEDBACK?.fullAccess &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Review Management (Full Access)</li>
                                        }
                                        {userData?.permissions[0]?.SUPPORT &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Grati Support</li>
                                        }
                                        {userData?.permissions[0]?.SUB_ADMIN_MANAGEMENT &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>{userData?.role !== 2 ? "Manage Admins / Sub-Admins" : "View Admins / Sub-Admins"}</li>
                                        }
                                        {userData?.permissions[0]?.FINANCE &&
                                            <li className='w-max h-5 text-[#888888] text-[14px] font-medium' style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}>Finance Management</li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div></>)}

            </div>
        );
    }
ManageView.propTypes = {
    userId: PropTypes.string,
};

export default ManageView

