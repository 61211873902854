import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Cards from "../dashboardHome/cards";
import Tablee from "../dashboardHome/table";
import { dashboardData } from "../../../API/dashboardApi";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
const Dashboard = () => {
  const [regUser, setRegUser] = useState(0);
  const [gratiCount, setGratiCount] = useState(0);
  const [giveThanksUser, setGiveThanksUser] = useState(0);
  const [getThanksUser, setGetThanksUser] = useState(0);
  const [totalTrans, setTotalTrans] = useState(0);
  const [transAmt, setTransAmt] = useState(0);
  const [dailySignUp, setDailySignUp] = useState(0);

  const permissions = useSelector((state) => state?.user?.permissions[0]);
  useEffect(() => {
    const cardDetails = async () => {
      try {
        const res = await dashboardData();
        setGratiCount(res?.data?.data?.gratitudeCount);
        setGetThanksUser(res?.data?.data?.totalGetThanksUser);
        setGiveThanksUser(res?.data?.data?.totalGiveThanksUser);
        setRegUser(res?.data?.data?.totalRegisteredUser);
        setDailySignUp(res?.data?.data?.totalUsersRegisteredToday);
        setTotalTrans(res?.data?.data?.totalTransactionCount);
        setTransAmt(res?.data?.data?.totalTransactionAmount);
      } catch (error) {
        toast.error("Error fetching dashboard data:", {
          autoClose: 5000,
          toastId: "dgxd",
        });
      }
    };

    cardDetails();
  }, []);

  const colors = [
    "#F1426D",
    "#7239EA",
    "#22C1AE",
    "#DE6F4C",
    "#4192F1",
    "#39CC7C",
    "#C657C8",
  ];
  const colors1 = [
    "#E21F4F",
    "#5D1EE2",
    "#009583",
    "#CA5D3B",
    "#3485E4",
    "#23B666",
    "#B42BB8",
  ];
  const cardData = [
    regUser,
    gratiCount,
    giveThanksUser,
    getThanksUser,
    totalTrans,
    transAmt,
    dailySignUp,
  ];
  const colorsView = ["#F1426D", "#7239EA"];
  const colorsView1 = ["#E21F4F", "#5D1EE2"];
  const cardDataView = [regUser, gratiCount];

  return (
    <div className="w-full randomClass1">
      <div className="flex flex-col gap-8 ">
        {!permissions || permissions?.DASHBOARD?.fullAccess ? (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 1 }}
            className="conta">
            {colors.map((backgroundColor, index) => (
              <div className="box" key={backgroundColor}>
                <Cards
                  cardData={cardData[index]}
                  imgIndex={index}
                  backgroundColor={colors[index]}
                  darkColor={colors1[index]}
                />
              </div>
            ))}
          </motion.div>
        ) : (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="conta">
            {colorsView.map((backgroundColor, index) => (
              <div className="box" key={backgroundColor}>
                <Cards
                  cardData={cardDataView[index]}
                  imgIndex={index}
                  backgroundColor={colorsView[index]}
                  darkColor={colorsView1[index]}
                />
              </div>
            ))}
          </motion.div>
        )}

        <div className="h-full">
          <Tablee />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
