import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../reducers/auth";
import userReducer from "../reducers/user";

const authPersistConfig = {
    key: "auth",
    storage,
    blacklist: [],
};

const userPersistConfig = {
    key: "user",
    storage,
    blacklist: [],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        user: persistedUserReducer,
    },
});

export const persistor = persistStore(store);

export default store;
