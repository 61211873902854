import React from "react";
import PropTypes from "prop-types";
import "../../manage_admins/manageEdit.css";
const ViewQuery = ({ setIsModalOpen, queryData }) => {
  const handleSendEmail = () => {
    window.location.href = `mailto:${queryData?.email}`;
    setIsModalOpen(false);
  };
  return (
    <div className="h-[85%] flex flex-col gap-5 font-semibold justify-between">
      <div className="card">
        <p
          className="text-[14px] font-[500] text-[#888888]"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          {queryData?.message}
        </p>
      </div>
      <div>
        <button
          className=" text-[#524CBB] !bg-[#EEEEEE] text-[14px] font-bold rounded-[6px] px-[16px] py-[10px]"
          style={
            queryData?.isClosed || !queryData?.userDetails?.userName
              ? { color: "#888888" }
              : {}
          }
          type="button"
          disabled={queryData?.isClosed || !queryData?.userDetails?.userName}
          onClick={handleSendEmail}>
          Send Email
        </button>
      </div>
    </div>
  );
};
ViewQuery.propTypes = {
  setIsModalOpen: PropTypes.func,
  queryData: PropTypes.object,
};
export default ViewQuery;
