import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import './sidenav.css';

const SideNavItem = ({ to, label, img }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Link to={to}>
            <ListItem
                className={`flex gap-[4px] flex-row justify-center items-center listItem ${isActive ? 'activeHover' : ''}`}
            >
                <img
                    src={img}
                    alt="icon"
                    className="w-[22px] h-[22px]"
                />
                <ListItemText
                    sx={{
                        fontFamily: "Plus Jakarta Sans",
                        lineHeight: "20px",
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "75px",
                        height: "22px",
                    }}
                    primary={label}
                />
            </ListItem>
        </Link>
    );
};

SideNavItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
};

export default SideNavItem;
