import React from 'react';
import Trash from "../../../../Assets/images/icons/trash_white.png";
import { delAdmin } from '../../../API/manageAdminApi';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const Delete = ({ setIsDeleted, setIsOpenModal, userType, id }) => {


    const handleClose = () => {
        setIsOpenModal(false);
    };

    const handleClick = () => {
        delAdmin({ _id: id, userType })
            .then((res) => {
                toast.success("User Deleted Successfully", {
                    autoClose: 5000, toastId: "egea"
                });

                setIsDeleted(true);
                handleClose();
            })
            .catch((error) => toast.error(error?.message, {
                autoClose: 5000, toastId: "rr4"
            }));
    };

    return (
        <div className='h-auto flex flex-col gap-5 font-semibold'>
            <div className="flex flex-col w-[343px] h-33 gap-6 m-auto ">
                <div className="gap-5 m-auto w-[78px] h-[78px] rounded-full bg-[#D33939]">
                    <img src={Trash} alt="" className="m-auto relative top-[20px] w-[39.92px] h-[39.82px]" />
                </div>
                <p className='font-[Plus Jakarta Sans] font-bold text-5 text-center w-[343px] h-[30px]'>Are you sure you want to delete?</p>
                <p className='font-[Plus Jakarta Sans] font-medium text-[#A1A0A3] text-5 text-center w-[343px] h-[30px]'>After this user is deleted, they won't be able to use the application. Are you sure you want to delete them?</p>
            </div>
            <div className="flex gap-4 w-[162px] h-11 mt-10 m-auto">
                <button className='w-[87px] bg-[#EEEEEE] rounded-[6px]' onClick={handleClose}> Cancel</button>
                <button className='w-[59px] bg-[#524CBB] rounded-[6px] text-white' onClick={handleClick}> Yes</button>
            </div>
        </div>
    );
};

Delete.propTypes = {
    setIsDeleted: PropTypes.func,
    setIsOpenModal: PropTypes.func,
    userType: PropTypes.string,
    id: PropTypes.string,
};

export default Delete;
