import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Forgetpassword from './App/screens/forgot_password/forget_password';
import Login from './App/screens/login/login';
import ResetPassword from './App/screens/reset_password/reset_password';
import OtpPage from './App/screens/OtpPage/otpPage';
import API from './App/API/urlConstants';
import { jwtDecode } from "jwt-decode";
import AuthenticatedRoutes from './App/Routers/AuthenticatedRoutes';
import { authActions } from './App/redux/reducers/auth';

function App() {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = localStorage.getItem(API.AUTH_TOKEN);
  useEffect(() => {
    try {
      jwtDecode(authToken);
    } catch (error) {
      dispatch(authActions.logout());
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    }
  }, [authToken]);

  return (
    <div className="App">
      {isLoggedIn ? <Routes><Route path="/*" element={<AuthenticatedRoutes />} /> </Routes>
        : <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/forgetpassword/otpverification" element={<OtpPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />

          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
