import axios from "axios";
import API from "./urlConstants";
const fetchClient = () => {
    let instance = axios.create();
    instance.interceptors.request.use((config) => {
        const token = localStorage.getItem(API.AUTH_TOKEN);
        if (token) config.headers[API.AUTHORIZATION_HEADER] = `Bearer ${token}`;
        return config;
    });
    return instance;
};
export default fetchClient();
