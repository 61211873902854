import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchNotification } from "../../../API/dashboardApi";
import { toast } from "react-toastify";
import "../../Dashboard/manage_admins/manageEdit.css";
import Moment from "react-moment";
import Logo from "../../../../Assets/images/icons/notificationLogo.png";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
const Notifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handlePageRedirection = (pageToRedirect) => {
    switch (pageToRedirect) {
      case "Finance Report":
        navigate("/finance_management", {});
        break;
      case "Reports page":
        navigate("/review_management", {
          state: {
            type: 3,
          },
        });
        break;
      case "Query Page":
        navigate("/grati_support", {});
        break;

      default:
        break;
    }
  };
  const fetchNotificationData = () => {
    setLoading(true);
    fetchNotification()
      .then((response) => {
        setNotificationData(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.messsage, {
          autoClose: 5000,
          toastId: "fgfv",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchNotificationData();
  }, [state?.eventTriggered]);
  return (
    <Paper
      className=" h-[100%] max-h-[87vh] pb-6 p-6 flex flex-col gap-6"
      style={{ borderRadius: "12px" }}
      component={Paper}>
      <div
        className="flex justify-between flex-wrap"
        style={{
          alignItems: "center",
          alignContent: "center",
        }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Notifications
        </p>
      </div>

      <div className="card flex flex-col gap-[20px] h-[100%]">
        {loading &&
          Array.from({ length: 10 }, (_, index) => (
            <motion.div
              key={`loading-${index}`}
              initial={{ opacity: 0 }}
              animate={{
                opacity: [0, 1, 0],
                transition: { duration: 0.8, repeat: Infinity },
              }}
              exit={{ opacity: 0 }}
              className="flex justify-center items-center p-4 bg-[#F8F8F8] h-[48px] rounded-xl">
              <div className="loader"></div>
            </motion.div>
          ))}
        {!loading && notificationData?.length === 0 && (
          <div className="flex flex-col justify-center items-center h-[100%]">
            <MarkUnreadChatAltIcon
              style={{
                width: "300px",
                height: "300px",
                color: "#888888",
                opacity: "0.2",
              }}
            />
            <p className=" mt-[15px] text-[18px] font-[600] text-[#888888]">
              No Notifications Yet
            </p>
          </div>
        )}
        {!loading &&
          notificationData?.map((item) => (
            <motion.div
              key={`item-${item}`}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 1 }}
              className="flex justify-between gap-[28px] p-[8px] bg-[#F8F8F8] rounded-xl mr-[5px]">
              <button
                type="button"
                className="flex justify-between gap-[28px] items-center	cursor-pointer"
                onClick={(e) => handlePageRedirection(item?.pageToRedirect)}>
                <img src={Logo} className="w-[36px] h-[36px]" alt="" />
                <p
                  style={{
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}>
                  {item?.message ? (
                    <p>
                      {item.message.split("deletedUser").map((part, index) => {
                        if (index === 0) {
                          return part;
                        } else {
                          return (
                            <>
                              <span className="italic text-[#888888]">
                                Deleted User {"  "}
                              </span>

                              {part}
                            </>
                          );
                        }
                      })}
                    </p>
                  ) : (
                    <p className="italic text-[#888888]">
                      No message to display
                    </p>
                  )}
                </p>
              </button>
              <p
                className=" text-[12px] text-[#888888] italic self-center pr-[2px]"
                style={{
                  fontFamily: "Plus Jakarta Sans,sans-serif",
                }}>
                {item?.createdAt ? (
                  <Moment format="DD MMM YYYY hh:mm A">
                    {item?.createdAt}
                  </Moment>
                ) : (
                  ""
                )}
              </p>
            </motion.div>
          ))}
      </div>
    </Paper>
  );
};

export default Notifications;
