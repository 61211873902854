import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "../../../../Assets/images/icons/search.png";
import Eye from "../../../../Assets/images/icons/eye.png";
import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  fetchOrder,
  searchOrderById,
  updateOrderStatus,
} from "../../../API/orderApi";
import { toast } from "react-toastify";
import Moment from "react-moment";
import ViewOrder from "./view_order";

const tableHeading = [
  "S. No.",
  "Order Id",
  "Date and Time",
  "Price",
  "Payment Status",
  "Order Status",
  "Action",
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
}));

const limitOptions = [10, 25, 50, 75, 100];
const orderStatus = [
  {
    value: "Order_received",
    label: "Order Received",
    backgroundColor: "#7469B6",
    color: "white",
  },
  {
    value: "Placed_for_instaprint",
    label: "Placed for Vista Print",
    backgroundColor: "#AD88C6",
    color: "white",
  },
  {
    value: "Fullfilled",
    label: "Fullfilled",
    backgroundColor: "#CA9DBC",
    color: "white",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
    backgroundColor: "#E1AFD1",
    color: "white",
  },
];
const OrderStatusDropdown = ({ orderId, actionStatus }) => {
  const [status, setStatus] = useState(actionStatus);
  const [selectedStyle, setSelectedStyle] = useState({});

  useEffect(() => {
    const currentStatus = orderStatus.find(
      (status) => status.value === actionStatus
    );
    if (currentStatus) {
      setSelectedStyle({
        backgroundColor: currentStatus.backgroundColor,
        color: currentStatus.color,
        borderRadius: "32px",
      });
    }
  }, [actionStatus]);

  const handleChange = (event) => {
    const newStatus = event.target.value;
    updateOrderStatus(orderId, {
      status: newStatus,
    })
      .then((response) => {
        setStatus(newStatus);
        const currentStatus = orderStatus.find(
          (status) => status.value === newStatus
        );
        if (currentStatus) {
          setSelectedStyle({
            backgroundColor: currentStatus.backgroundColor,
            color: currentStatus.color,
            borderRadius: "32px",
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "Error changing order status",
        });
      });
  };

  return (
    <Select
      id="order-status-select"
      value={status}
      onChange={handleChange}
      style={selectedStyle}>
      {orderStatus.map((status) => (
        <MenuItem
          key={status.value}
          value={status.value}
          style={{
            backgroundColor: status.backgroundColor,
            color: status.color,
            margin: "10px",
          }}>
          {status.label}
        </MenuItem>
      ))}
    </Select>
  );
};
OrderStatusDropdown.propTypes = {
  orderId: PropTypes.string.isRequired,
  actionStatus: PropTypes.string.isRequired,
};
const OrderManagement = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [viewOrder, setViewOrder] = useState(false);
  const [orderId, setOrderId] = useState("");
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleSearchClear = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery("");
    handleSearch("");
  };

  const handleChange = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery(event?.target?.value);
    handleSearch(event?.target?.value);
  };
  const handleSearch = async (value) => {
    if (value !== "") {
      setLoading(true);
      searchOrderById(value)
        .then((response) => {
          setTableData(response?.data?.data);
          setTotalCount(0);
        })
        .catch((error) =>
          toast.error("Error Fetching Order", {
            autoClose: 5000,
            toastId: "Error Fetching Order",
          })
        )
        .finally(() => setLoading(false));
    } else if (value === "") {
      handleOrderData();
    }
  };
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };
  const handleViewOrder = (id) => {
    setOrderId(id);
    setViewOrder(true);
  };
  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );
  const renderActionButtons = (id) => (
    <div className="flex gap-2">
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        onClick={() => handleViewOrder(id)}>
        <img src={Eye} alt="" className="w-5 h-5" />
      </button>
    </div>
  );
  const handleOrderData = () => {
    setLoading(true);
    fetchOrder(page, limit)
      .then((response) => {
        setTableData(response?.data?.data?.orderList);
        setTotalCount(response?.data?.data?.totalCount);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "error fetching collab post data",
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleOrderData();
  }, [page, limit]);

  return (
    <Paper
      className="h-[100%] max-h-[87vh] pb-6 p-6 flex flex-col gap-6"
      style={{ borderRadius: "12px" }}
      component={Paper}>
      {viewOrder && (
        <ViewOrder orderId={orderId} setIsViewOpen={setViewOrder} />
      )}
      {!viewOrder && (
        <>
          <div
            className="flex justify-between flex-wrap"
            style={{
              alignItems: "center",
              alignContent: "center",
            }}>
            <p
              className="text-[18px] font-[700] text-black"
              style={{ fontFamily: "Plus Jakarta Sans" }}>
              Order Management
            </p>
            <div
              className="flex gap-3 h-11 font-[600] text-[14px]"
              style={{ fontFamily: "Plus Jakarta Sans" }}>
              <Search className="flex flex-row justify-between relative !mr-0">
                <SearchIconWrapper>
                  <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search By Order Id"
                  value={searchQuery}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "search" }}
                  className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
                />
                {isSearchFilled && (
                  <button
                    className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                    onClick={handleSearchClear}>
                    X
                  </button>
                )}
              </Search>
            </div>
          </div>
          {loading && (
            <div className="flex items-center justify-center mt-20">
              <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
            </div>
          )}
          {!loading && (
            <TableContainer className="card overflow-auto">
              {tableData?.length !== 0 ? (
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.8 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className="w-[194px] h-11 p-3">
                        {tableHeading.map((heading) => (
                          <TableCell key={heading} className="w-max h-11 p-3">
                            <p
                              className="w-max text-[14px] font-[600] text-[#888888]"
                              style={{ fontFamily: "Plus Jakarta Sans" }}>
                              {heading}
                            </p>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {tableData?.map((row, index) => (
                        <TableRow
                          key={row?._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className="w-[194px] h-13">
                          {renderTableCell(<p>{index + 1}</p>)}
                          {renderTableCell(
                            row?.manualOrderId || renderTextContent("N/A")
                          )}
                          {renderTableCell(
                            <Moment format="DD MMM YYYY ,  hh:mm A">
                              {row?.createdAt}
                            </Moment>
                          )}
                        {
  renderTableCell(
    row?.orderPrice ? `$${row.orderPrice.toFixed(2)}` : renderTextContent("N/A")
  )
}
                          {renderTableCell(
                            row?.paymentMethod || renderTextContent("N/A")
                          )}
                          {renderTableCell(
                            <OrderStatusDropdown
                              actionStatus={row?.adminAction}
                              orderId={row?._id}
                            />
                          )}
                          {renderTableCell(renderActionButtons(row?._id))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </motion.div>
              ) : (
                <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
                  No data found
                </p>
              )}
            </TableContainer>
          )}

          <div className="flex justify-between mt-auto">
            <Select
              labelId="pagination-limit-select-label"
              value={limit}
              onChange={handlePaginationLimitChange}
              className="w-[66px] h-11 bg-[#EEEEEE]"
              style={{
                color: "#888888",
                fontSize: "14px",
                fontWeight: "500",
                width: "fit-content",
              }}>
              {limitOptions.map((options) => (
                <MenuItem
                  key={options}
                  style={{
                    "&:hover": {
                      backgroundColor: "#EEEEEE",
                    },
                  }}
                  value={options}>
                  {options}
                </MenuItem>
              ))}
            </Select>

            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(totalCount / limit)}
                defaultPage={1}
                siblingCount={3}
                shape="rounded"
                page={page}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </>
      )}
    </Paper>
  );
};

export default OrderManagement;
