import React, { useState, useEffect } from 'react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, Sidebar, ConversationList, Conversation, ChatContainer, MessageList, Message, MessageInput, Avatar, ConversationHeader, MessageGroup, MessageSeparator } from "@chatscope/chat-ui-kit-react";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "../../../../Assets/images/icons/search.png";
import InputBase from "@mui/material/InputBase";
import { MenuItem, Select } from "@mui/material";
import User from "../../../../Assets/images/icons/user.png";
import "./support.css";
import { toast } from "react-toastify";
import { getChatList, addMessageToRoom, listenForRealtimeMessages, updateUnreadCount } from './firebaseQueries';
import CloseQuery from './closeQuery';
import GenericModal from '../../../GenericModal';
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  margin: "auto",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  width: "[331px]",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Support = () => {
  const [userData, setUserData] = useState({});
  const [chatList, setChatList] = useState([]);
  const [isChat, setIsChat] = useState(false);
  const [queryId, setQueryId] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatQueryId, setChatQueryId] = useState("");
  const [isClose, setIsClose] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState([]);
  const [isClosed, setIsClosed] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleChange = (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    setIsSearchFilled(searchText !== "");
    filterChatList(searchText);
  };

  const handleSearchClear = () => {
    setSearchValue("");
    setIsSearchFilled(false);
    setFilteredChatList(chatList);
  };

  const filterChatList = (searchText) => {
    if (searchText === "") {
      setFilteredChatList(chatList);
      return;
    }
    const filteredChats = chatList.filter((chat) =>
      chat?.usersReadDetails[0]?.userDetail?.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredChatList(filteredChats);
  };

  const formatSentDate = (timestamp) => {
    const now = new Date();
    const date = timestamp?.toDate() || now;
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    if (isToday) {
      return "Today";
    }

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday =
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();

    if (isYesterday) {
      return "Yesterday";
    }

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleChat = async (data) => {
    setLoadingMessages(true);
    const unreadArray = [0, 0]
    setUserData(data);
    setIsChat(true);
    updateUnreadCount(data?.roomId, data?.usersReadDetails[1]?.adminId, data?.usersReadDetails[0]?.userId, unreadArray)
      .then(messages => {
        setLoadingMessages(false);
      }).catch(error => {
        toast.error("Error fetching messages:", { autoClose: 5000, toastId: "error getting message" });
      });;
    setChatQueryId(data?.queryID.substring(0, data?.queryID.indexOf('-') + 1) + data?.queryID.substring(data?.queryID.lastIndexOf('-') + 1));
    setIsClose(data?.isClosed)

  };
  const handleIsClosed = (event) => {
    setIsModalOpen(!isModalOpen);
  };

  const sendMsg = () => {
    const trimmedMessage = messageText.replace(/&nbsp;/g, ' ').trim();
    if (trimmedMessage !== "") {
      try {
        setMessageText("");
        addMessageToRoom(userData?.roomId, userData?.usersReadDetails[0]?.userId, userData?.usersReadDetails[1]?.adminId, messageText);
        const unreadArray = [1, 0]
        updateUnreadCount(userData?.roomId, userData?.usersReadDetails[1]?.adminId, userData?.usersReadDetails[0]?.userId, unreadArray);
      } catch (error) {
        toast.error("Error sending message:", {
          autoClose: 5000,
          toastId: "error sending message",
        });
      }
    }
    else {
      setMessageText("");
    }
  }

  const formatSentTime = (timestamp) => {
    let timeString = "";
    const now = new Date();
    const date = timestamp?.toDate() || now;

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    timeString = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

    if (!isToday) {
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      const isYesterday =
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear();

      if (isYesterday) {
        timeString = `Yesterday, ${timeString}`;
      } else {
        timeString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${timeString}`;
      }
    }

    return timeString;
  };

  const renderMessageGroup = (message, currentMessageDate, isDateChanged, loadingMessages) => {
    return (

      <React.Fragment key={message?._id}>
        {isDateChanged && (
          <MessageSeparator>
            <span>
              {currentMessageDate}
            </span>
          </MessageSeparator>
        )}
        <MessageGroup
          direction={message.senderId === userData?.usersReadDetails[1]?.adminId ? 'outgoing' : 'incoming'}
          sender={message.senderId === userData?.usersReadDetails[1]?.adminId ? 'You' : userData?.usersReadDetails[0]?.userDetail?.name}
          avatarPosition={message.senderId === userData?.usersReadDetails[1]?.adminId ? 'br' : 'bl'}
          sentTime={formatSentTime(message.createdAt)}
        >
          <MessageGroup.Messages>
            <div>
              <span style={{ fontFamily: 'Plus Jakarta Sans', fontSize: '10px', fontWeight: 600 }}>
                {message.senderId === userData?.usersReadDetails[1]?.adminId ? 'You' : userData?.usersReadDetails[0]?.userDetail?.name}
              </span>{"  "}
              <span style={{ fontFamily: 'Plus Jakarta Sans', fontSize: '8px', fontWeight: 400, color: "#888888" }}>
                {formatSentTime(message.createdAt)}
              </span>
              <Message
                model={{
                  message: message.text,
                  position: "last"
                }}
              />
            </div>
          </MessageGroup.Messages>
        </MessageGroup>
      </React.Fragment>
    )

  };

  useEffect(() => {
    const unsubscribe = getChatList(setLoading, (chatList) => {
      setChatList(chatList);
      setFilteredChatList(chatList);
      if (chatList.length === 0) {
        setUserData([]);
        setIsChat(false);
      }
    }, setQueryId, setUnreadCount, setIsClosed);


    return () => unsubscribe();

  }, []);
  useEffect(() => {
    if (isChat && userData) {
      const unsubscribe = listenForRealtimeMessages(userData.roomId, (messages) => {
        setMessages(messages);

      });
      return () => {
        unsubscribe();
      };
    }
    if (userData && (userData.length > 0 && (userData?.usersReadDetails[0]?.userId === userData?.last_message?.userId))) {
      const unreadArray = [0, 0]
      updateUnreadCount(userData?.roomId, userData?.usersReadDetails[1]?.adminId, userData?.usersReadDetails[0]?.userId, unreadArray);
    }
  }, [isChat, userData]);

  return (

    <MainContainer className="p-6 rounded-xl h-[100%] max-h-[85vh]">
      <Sidebar position="left" className='relative'>
        <Search className="flex flex-row justify-between relative !m-0">
          <SearchIconWrapper>
            <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by User Name"
            value={searchValue}
            onChange={handleChange}
            inputProps={{ "aria-label": "search" }}
            className="flex w-[100%] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
          />
          {isSearchFilled && (
            <button
              className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[18px]"
              onClick={handleSearchClear}
            >
              X
            </button>
          )}
        </Search>
        {filteredChatList.length > 0 ? <ConversationList loading={loading}>
          {filteredChatList?.map((data, index) => (data &&
            <Conversation
              className={isClosed[index] ? "chat_closed" : ""}
              onClick={() => handleChat(data)}
              key={queryId[index]}
              name={data?.usersReadDetails[0]?.userDetail?.name}
              info={`Query ID:  #${queryId[index]?.substring(0, queryId[index]?.indexOf('-') + 1)}${queryId[index]?.substring(queryId[index]?.lastIndexOf('-') + 1)}`}
              active={userData?.queryID === queryId[index]}
              unreadDot={unreadCount[index] > 0}
            >
              <Avatar
                key={`Q${queryId[index]}`}
                src={data?.usersReadDetails[0]?.userDetail?.profileImage || User}
                style={{ height: "44px", width: "44px" }}
                className="w-[46px] h-[38px] rounded-md bg-gray-300"
                alt="img"
              />
            </Conversation>
          ))}
        </ConversationList> : <div className="onChatLoad">No Queries Found</div>}
      </Sidebar>
      {isChat ? (
        <ChatContainer className="gap-7 p-6">
          <ConversationHeader key={`Query #${chatQueryId}`} style={{ paddingBottom: "31px" }}>
            <Avatar
              key={chatQueryId}
              src={userData?.usersReadDetails[0]?.userDetail?.profileImage || User}
              style={{ height: "52px", width: "52px" }}
              className="w-[46px] h-[38px] rounded-md bg-gray-300"
              alt=""
            />
            <ConversationHeader.Content
              key={`Query ${chatQueryId}`}
              userName={userData?.usersReadDetails[0]?.userDetail?.name}
              info={`Query ID: #${chatQueryId}`}
            ></ConversationHeader.Content>
            <ConversationHeader.Actions>
              <Select
                key={"query_status"}
                labelId="pagination-limit-select-label"
                value={isClose || false}
                displayEmpty
                onChange={handleIsClosed}
                className=" bg-[#E1FFD7] w-[95px] h-11 rounded-[6px] gap-1 py-2 px-[14px]"
                style={{
                  fontFamily: "Inter",
                  lineHeight: "20px",
                  fontWeight: "700",
                  textSize: "14px",
                  padding: "0",
                  color: "#42751B",
                }}
                disabled={isClose}
              >
                <MenuItem
                  key={"open"}
                  style={{
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  value={false}
                >
                  Open
                </MenuItem>
                <MenuItem
                  key={"close"}
                  style={{
                    "&:hover": {
                      backgroundColor: "#EEEEEE",
                    },
                  }}
                  value={true}
                >
                  Close
                </MenuItem>
              </Select>
              {isModalOpen && (
                <GenericModal
                  open={isModalOpen}
                  handleClose={() => setIsModalOpen(false)}
                  content={<CloseQuery setIsModalOpen={setIsModalOpen} setIsClose={setIsClose} userData={userData} />}
                  isCross={true}
                  width="391px"
                  height="323px"
                />
              )}
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList autoScrollToBottomOnMount={true} autoScrollToBottom={true} className="message-list">
            {loadingMessages ? (
              <MessageList.Content className='flex flex-col h-[100%] text-center font-semibold	text-lg	text-[#888888] justify-center'              >
                Loading Messages ...
              </MessageList.Content>
            ) : (
              messages.length > 0 && messages?.map((message, index) => {
                const currentMessageDate = formatSentDate(message.createdAt);
                const previousMessageDate = index > 0 ? formatSentDate(messages[index - 1].createdAt) : null;
                const isDateChanged = previousMessageDate !== currentMessageDate;
                return renderMessageGroup(message, currentMessageDate, isDateChanged);
              }))}
          </MessageList>
          <MessageInput
            placeholder="Type Something..."
            value={messageText}
            onChange={(e) => setMessageText(e)}
            onSend={sendMsg}
            sendButton={true}
            attachButton={false}
            disabled={isClose}
          />
        </ChatContainer>
      ) : <div className="onload">Provide Support to others</div>}
    </MainContainer>

  );
};

export default Support;
